import React, { useEffect, useState } from "react";
import styles from "./AddGalleryModal.module.css";
import uploadImg from "../../../assets/upload-img.png";
import { Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  GetCreateGallery,
  GetEditGallery,
} from "../../../handlers/gallery.handler";
import LoadingSpinner from "../../../components/icons/LoadingSpinner";

const initialForm = {
  image: null,
  title: "",
  description: "",
  type: "",
  videoUrl: "",
};

const AddGalleryModal = ({ show, onClose, onSubmit, updateGallery }) => {
  const [form, setForm] = useState({ ...initialForm });
  const [selectVideoLoading, setSelectVideoLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getVideoThumbnail = (videoUrl) => {
    setSelectVideoLoading(true);

    const video = document.createElement("video");
    video.src = videoUrl;

    video.addEventListener("loadeddata", () => {
      if (video.readyState >= 2) {
        video.currentTime = 1; // Seek to 1 second to capture a frame
      }
    });
    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = 200; // Set desired width
      canvas.height = 150; // Set desired height
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      console.log("thumbnail url: " + thumbnailUrl);
      setSelectedImage(thumbnailUrl); // Set thumbnail as selected image
      setSelectVideoLoading(false);
    });
  };

  const updateFormWithExistingData = () => {
    const obj = {
      id: updateGallery._id,
      title: updateGallery.title,
      description: updateGallery.description,
      type: updateGallery.type,
    };
    if (updateGallery.type === "url") {
      obj["videoUrl"] = updateGallery.image;
    } else if (updateGallery.type === "image") {
      obj["image"] = updateGallery.image;
      setSelectedImage(updateGallery.image);
    } else if (updateGallery.type === "video") {
      getVideoThumbnail(updateGallery.image);
    }

    setForm(obj);
  };

  useEffect(() => {
    console.log("updateGallery", updateGallery);
    if (updateGallery) {
      updateFormWithExistingData();
    }
  }, [updateGallery]);

  const selectImageHandler = (event) => {
    const file = event.target.files[0];
    console.log("file", file);

    const formCopy = { ...form };
    formCopy.image = file;
    // setForm({ ...form, image: file });

    if (file.type.includes("image")) {
      const imgUrl = URL.createObjectURL(file);
      formCopy.type = "image";
      setSelectedImage(imgUrl);
    } else if (file.type.includes("video")) {
      formCopy.type = "video";

      const videoUrl = URL.createObjectURL(file);
      // setSelectedImage(videoUrl);

      // Generate and set video thumbnail
      getVideoThumbnail(videoUrl);
    }
    setForm(formCopy);
  };

  const formChangehandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setForm({ ...form, [name]: value });
  };

  const handleRemoveMedia = () => {
    setSelectedImage("");
    setForm({ ...form, image: null });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (form.image) {
      formData.append("image", form.image);
      formData.append("type", form.type);
    } else {
      formData.append("imageUrl", form.videoUrl);
      formData.append("type", "url");
    }

    formData.append("description", form.description);
    formData.append("title", form.title);

    if (updateGallery) {
      formData.append("_id", updateGallery._id);
    }

    // console.log("submit form", form);

    setIsLoading(true);

    if (updateGallery) {
      GetEditGallery(formData).then((response) => {
        console.log("create response", response);
        if (response.status) {
          handleCloseModal();
          onSubmit();
        } else {
          updateFormWithExistingData();
        }
        setIsLoading(false);
      });
    } else {
      GetCreateGallery(formData).then((response) => {
        console.log("create response", response);
        if (response.status) {
          handleCloseModal();
          onSubmit();
        }
        setIsLoading(false);
      });
    }
  };

  const handleCloseModal = () => {
    setForm(initialForm);
    setSelectedImage("");
    onClose();
  };

  return (
    show && (
      <>
        <form onSubmit={handleSubmitForm} className={styles.modal}>
          <button
            type="button"
            className={styles.closeBtn}
            onClick={handleCloseModal}
          >
            <Close />
          </button>

          <div
            className={`${styles.imageContainer} ${
              selectedImage ? styles.selectedImage : ""
            }`}
          >
            {selectedImage && (
              <button
                className={styles.removeImageBtn}
                onClick={handleRemoveMedia}
              >
                <Close />
              </button>
            )}
            <img
              src={selectedImage ? selectedImage : uploadImg}
              alt="upload-img"
            />
            {!selectedImage && (
              <input
                required={!form.videoUrl}
                disabled={form.videoUrl || selectVideoLoading}
                type="file"
                accept=".png, .jpg, .jpeg, .mp4, .mov, .avi"
                onChange={selectImageHandler}
              />
            )}
          </div>

          <input
            placeholder="Video URL"
            name="videoUrl"
            required={!form.image}
            className={styles.videoUrlInput}
            disabled={form.image}
            value={form.videoUrl}
            onChange={formChangehandler}
          />

          <input
            required
            placeholder="Title"
            name="title"
            onChange={formChangehandler}
            value={form.title}
          />
          <textarea
            required
            placeholder="Description"
            rows={5}
            name="description"
            className={styles.descriptionTextarea}
            onChange={formChangehandler}
            value={form.description}
          />

          <Button
            variant="contained"
            type="submit"
            className={styles.submitBtn}
            style={{
              fontWeight: "bold",
              marginTop: 15,
              padding: 10,
              width: "100%",
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : updateGallery ? (
              "Update Gallery"
            ) : (
              "Add Gallery"
            )}
          </Button>
        </form>

        <div className={styles.overlay} onClick={handleCloseModal} />
      </>
    )
  );
};

export default AddGalleryModal;
