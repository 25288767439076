import React, { useState, useEffect, Fragment } from 'react';
import './Banners.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import ReadOnlyRow from '../../components/ReadOnlyRow';
import EditableRow from '../../components/EditableRow';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  GetAllBanners,
  GetCreateBanner,
  GetDeleteBanner,
  GetUpdateBanner,
} from '../../handlers/banner.handler';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function Banners() {
  const [file, setFile] = useState('');
  const [editHeading, setEditHeading] = useState(false);
  const [preview, setpreview] = useState(false);
  const [data, setData] = useState([]);
  const [editContactId, setEditContactId] = useState(null);
  const [bannerId, setBannerId] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [userInfo, setUserInfo] = useState({
    title: '',
    description: '',
  });

  const [editFormData, setEditFormData] = useState({
    title: '',
    description: '',
  });
  const [editImage, setEditImage] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });

  useEffect(() => {
    getBanner();
  }, [preview, editHeading]);

  const getBanner = () => {
    setIsLoadingMore(true);
    GetAllBanners().then((response) => {
      if (response.message === 'success') {
        setData(response.data.data);
        setIsLoadingMore(false);
      } else alert('Something went wrong');
    });
  };

  const clearState = () => {
    setEditFormData({
      title: '',
      description: '',
    });
    setEditImage('');
    setUserInfo({
      title: '',
      description: '',
    });
    setFile('');
    setpreview(false);
  };

  const handleFileInput = (e) => {
    // const name = e.target.name;
    // const value = e.target.value;
    // setUserInfo({ ...userInfo, [name]: value });
    let file = e.target.files[0];
    e.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 927 && width === 1920) {
          setFile(file);
          setpreview(true);
        } else {
          alert('Image width and height must be 1920x927 px');
          return false;
        }
      };
    };
    reader.onerror = function (error) {};
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const handleEditFormChanges = (e) => {
    setpreview(false);
    // const name = e.target.name;
    // const value = e.target.value;
    // setEditFormData({ ...editFormData, [name]: value });
    let file = e.target.files[0];
    e.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 927 && width === 1920) {
          setEditImage(file);
          setpreview(false);
          //setpreview(true);
        } else {
          alert('Image width and height must be 1920x927 px');
          return false;
        }
      };
    };
    reader.onerror = function (error) {};
  };

  const handleEditInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleEditClick = (event, row) => {
    console.log('row', row);
    event.preventDefault();
    setEditContactId(row._id);

    const formValues = {
      title: row.title,
      description: row.description,
    };
    const formimage = {
      image: row.image,
    };

    setEditFormData(formValues);
    setEditImage(formimage);
  };
  const handleDeleteClick = async (rowId) => {
    setBannerId(rowId);
    showModalForPublish();
  };
  const showModalForPublish = () => {
    let title = 'Delete Banner ?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };
  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const onSuccess = () => {
    setIsLoadingMore(true);
    GetDeleteBanner(bannerId).then((response) => {
      if (response.status === true) {
        setIsLoadingMore(false);
        alert(response.message);
        clearState();

        setConfirmationModal({
          message: '',
          show: false,
          title: '',
        });
        getBanner();
      }
    });
  };

  const saveEditInformation = async (event) => {
    setpreview(false);
    // setpreview(true);
    event.preventDefault();
    setEditHeading(true);
    const editData = {
      image: editImage,
      title: editFormData.title,
      description: editFormData.description,
      _id: editContactId,
    };

    console.log(editData);

    const editformData = new FormData();

    Object.keys(editData).forEach(function (key) {
      editformData.append(key, editData[key]);
    });

    GetUpdateBanner(editformData)
      .then((response) => {
        setIsLoadingMore(true);
        if (response.status === true) {
          // toast('Banner update successfully')
          console.log('done from edit', response);
          alert('Banner update successfully');
          clearState();
          setpreview(false);
          getBanner();
          setIsLoadingMore(false);
          window.location.reload();
        }
      })
      .catch((error) => console.log('update response error', error));
  };
  const saveInformation = async () => {
    if (userInfo.title !== '' && userInfo.description !== '') {
      const data = {
        image: file,
        title: userInfo.title,
        description: userInfo.description,
      };

      const formData = new FormData();

      Object.keys(data).forEach(function (key) {
        formData.append(key, data[key]);
      });
      setIsLoadingMore(true);
      GetCreateBanner(formData).then((response) => {
        if (response.status === true) {
          setIsLoadingMore(false);
          alert(`${response.data.message}`);
          //  toast(response.data.messages)

          setpreview(false);
          clearState();
          getBanner();
        }
      });
    } else if (userInfo.title === '') {
      setIsLoadingMore(false);
      toast.error('Please provide  title', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'style',
      });
    } else if (userInfo.description === '') {
      setIsLoadingMore(false);
      toast.error('Please provide  description', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'style',
      });
    }
  };
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {confirmationModal.show && (
          <ConfirmationModal
            message={confirmationModal.message}
            title={confirmationModal.title}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
        <div className="topnew" style={{ marginTop: '3px' }}>
          <h1 style={{ color: 'black' }}> Home Page Banner </h1>
        </div>
        <div className="topnew">
          <form>
            <div className="">
              <div
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                Click here to upload the banner
              </div>
              <input
                type="file"
                name="image"
                onChange={handleFileInput}
                id="file"
                style={{
                  backgroundColor: '#000',
                  height: 35,
                  width: 100,
                  position: 'absolute',
                  opacity: 0,
                  cursor: 'pointer',
                }}
              />
              <label htmlFor="file">
                <Button
                  variant="contained"
                  component="span"
                  htmlFor="file"
                  style={{ fontWeight: 'bold' }}
                >
                  Upload
                  <FileUploadIcon />
                </Button>{' '}
              </label>
            </div>
          </form>
        </div>

        {preview === true ? (
          <div className="bottomnew">
            <div className="leftnew">
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
                }
                alt=""
              />
            </div>
            <div className="rightnew">
              <form>
                <div className="formInput">
                  <label style={{ fontWeight: 'bold' }}> Title</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={userInfo.title}
                    onChange={handleInput}
                  />
                </div>
                <div className="formInput">
                  <label style={{ fontWeight: 'bold' }}>Sub Title</label>
                  <input
                    type="text"
                    name="description"
                    placeholder="Enter sub title"
                    value={userInfo.description}
                    onChange={handleInput}
                  />
                </div>
                <button
                  type="button"
                  onClick={saveInformation}
                  style={{ marginLeft: '194px', marginTop: '54px' }}
                >
                  Save
                </button>

                <button
                  type="button"
                  onClick={() => setpreview(false)}
                  style={{ marginRight: '278px', marginTop: '54px' }}
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        ) : (
          ''
        )}

        {data &&
          data.map((row) => (
            <Fragment>
              {editContactId === row._id ? (
                <EditableRow
                  editFormData={editFormData}
                  editImage={editImage}
                  handleEditFormChanges={handleEditFormChanges}
                  saveEditInformation={saveEditInformation}
                  setpreview={setpreview}
                  handleEditInput={handleEditInput}
                />
              ) : (
                <ReadOnlyRow
                  row={row}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                />
              )}
            </Fragment>
          ))}
      </div>
      <div style={{ textAlign: 'center', padding: 10 }}>
        <ScaleLoader
          color="#23A5E0"
          loading={isLoadingMore}
          css={override}
          size={20}
        />
      </div>
        <ToastContainer />
    </div>
  );
}

export default Banners;
