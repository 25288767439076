import React,{useState,useEffect} from 'react'
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import "./createcareer.css"
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Button from '@mui/material/Button';
import ReactQuill from 'react-quill';
import {GetCreateCareer} from '../../handlers/career.handler'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import { ContentCutOutlined } from '@mui/icons-material';
import {CKEditor} from "@ckeditor/ckeditor5-react"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Box from '@mui/material/Box';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DesktopDatePicker from "@mui/lab/DatePicker";
import TextField from '@mui/material/TextField';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import dateFormat from 'dateformat'
import '../../assets/style.css'
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function CreateCareer() {
  const initialState = {
    job_title: "",
    job_description: "",
    category: "",
    created_By: "",
    job_Location: "",
  };
    const [file, setFile] = useState('');
    const [catetory , setcatetory] = useState(false);
     const [value, setValue] = React.useState(new Date());
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [userInfo, setUserInfo] = useState({
      job_title: "",
    
      category: "",
      created_By: "",
      job_Location: "",
       
      });
      const [description,setDescription] = useState('');
      useEffect(()=>{},[catetory])
      const onCategorySelected = (e) => {
        setUserInfo({ ...userInfo, catrgoryId: e.target.value });
      };

     
      
  const clearState = () => {
    setDescription('')
    setUserInfo({job_title: "",
   // job_description: "",
    category: "",
    created_By: "",
    job_Location: "",
     
    });
  

  };

      const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserInfo({ ...userInfo, [name]: value });
       };
      const cancleInformation =()=>{
        setcatetory(false)
        setDescription('')
        
       clearState()
      }
      const saveInformation = () =>{
        console.log(userInfo);
        setIsLoadingMore(true)
        if(userInfo.job_title !== "" && userInfo.job_description !== "" && userInfo.category !== "" && userInfo.created_By !== "" && value !==""){
        const data ={
          job_title:userInfo.job_title,
          job_description:description,
          category: userInfo.category,
          created_By: userInfo.created_By,
         job_Location: userInfo.job_Location,
       
        }
     
    GetCreateCareer(data).then((response) => {
          if (response.status === true) {
            console.log("case",response.data)
            // alert(`${response.data.message}`);
            setIsLoadingMore(false)
            toast.info(`${response.data.message}`, {
              className:"style",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }
       
        clearState()
        }
        
        );
      }
      else if(userInfo.job_title === ''){
        setIsLoadingMore(false)
        toast.error('Please provide job title', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
     else if(userInfo.job_description  === ''){
      setIsLoadingMore(false)
        toast.error('Please provide job description', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else if(userInfo.category === ''){
        setIsLoadingMore(false)
        toast.error('Please provide category', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else if(userInfo.created_By === ''){
        setIsLoadingMore(false)
        toast.error('Please Enter Created By', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
        else if(value === ''){
        setIsLoadingMore(false)
        toast.error('Please Enter Date ', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else{
        setIsLoadingMore(false)
        toast.error('Error', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    }
  return (
    <div className='cases'>
      <Sidebar />
      <div className="newContainer">
      <Navbar />
      <>
     

      <div className="relative md:ml-64" style={{height:'105%'}} >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div className="row">
              <div className="col-lg-12">
                {/* <h1 className="addArticle_heading">
                  All Fields are mandatory:
                </h1> */}
                
                <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label"> Job Title*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Job Title *"
                  name="job_title"
                  value={userInfo.job_title}
                  style={styles.inputBox}
                  onChange={handleInput}
                />
              </div>
              </div>
            </div>
            <div className="row">
              <div style={{ marginTop: '20px' }}>
                <label className="addArticle_label">Job Description*</label>
                <br></br>
                {/* <input
                  type="text"
                  placeholder="Job Description *"
                  name="job_description"
                  value={userInfo.job_description}
                  style={styles.inputBox}
                  onChange={handleInput}
                /> */}
                <CKEditor
                      editor={ClassicEditor}
                      data={description}
                   //   onChange={event => setDescription(event.target.value)}
                      onChange={ ( event, editor ) => {
                        setDescription(editor.getData())
                        
                    } }
                      // onChange={(event, editor) => {
                      //   setUserInfo({
                      //     ...userInfo,
                      //     job_description: editor.getData(),
                      //   });
                      // }}
                    />
              </div>
            
              {/* <div style={{ marginTop: '20px' }}>
                <label className="addArticle_label">Category*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Category *"
                  name="category"
                  value={userInfo.category}
                  style={styles.inputBox}
                  onChange={handleInput}
                />
                 
               
              </div> */}
              <div>
                <>
                  <select
                    style={{
                    //   marginLeft: 20,
                      border: '2px solid #acb9ce',
                      borderRadius: 10,
                     
                      width:'160px',
                      height:'47px'
                    }}
                    name="category"
                    className="mb-5 mt-3"
                     value={userInfo.category}
                  onChange={handleInput}
                  >
                    <option value="">Category</option>
                    <option value="admin">Admin</option>
                    <option value="hr">Hr</option>
                    <option value="software Engineer">Software Engineer</option>
                    <option value="operations">Operations</option>
                    <option value="sales">Sales</option>

                  </select>
                </>
              </div>
            
              <div >
              <label className="addArticle_label">Created By</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Created By"
                  name="created_By"
                  value={userInfo.created_By}
                  style={styles.inputBox}
                  onChange={handleInput}
                />
              </div>

              <div >
              <label className="addArticle_label">Job Location</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Job Location"
                  name="job_Location"
                  value={userInfo.job_Location}
                  style={styles.inputBox}
                  onChange={handleInput}
                />
              </div>
              
               <br></br>
             <div className="col-lg-12">
                
                <Button variant="contained" onClick={saveInformation}
                  className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`} style={{backgroundColor:"green"}}>Save</Button>
               
                <Button variant="contained" onClick={cancleInformation}
                  className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`} style={{backgroundColor:"rgb(220,20,60)", marginLeft:"10px"}}>Cancel</Button>
              </div>
            </div>
          </div>
        </div>
        <ScaleLoader
          color="#23A5E0"
          loading={isLoadingMore}
          css={override}
          size={20}
        />
      </div>
      
    </>

      </div>
        <ToastContainer />
    </div>
  )
}
const styles = {
    inputBox: {
      width: '500px',
      height: '30px',
      marginTop: 5,
      borderRadius: '5px',
    },
  };

export default CreateCareer