import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Button from '@mui/material/Button';
import editIcon from '../../assets/edit.png';
import closeIcon from '../../assets/close.png';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  GetUpdateCaseStudies,
  GetEditCaseStudies,
} from '../../handlers/caseStudies.handler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import getCaseStudiesList from './getCaseStudiesList';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function UpdateCaseStudies() {
  const navigate = useNavigate();
  const params = useParams();
  const [file, setFile] = useState('');
  const [num, setNum] = useState(0);
  const [imagefile, setimageFile] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [editTags, setEditTags] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editChallenge, setEditChallenge] = useState(false);
  const [editSolution, setEditSolution] = useState(false);
  const [editOutcome, setEditOutcome] = useState(false);
  const [isClientName, setIsClientName] = useState(false);
  const [userInfo, setUserInfo] = useState({
    file: '',
    title: '',
    description: '',
    challenge: '',
    solution: '',
    result: '',
    client: '',
    location: '',
    category: '',
    tags: '',
    video: '',
  });
  const cancleInformation = () => {
    navigate('/allcasestudies');
    // setUserInfo({
    // file:'',
    // title: '',
    // description: '',
    // challenge:'',
    // solution:'',
    // result:'',
    // client:'',
    // location:'',
    // catrgoryId:"",
    // tags:'',
    // youtube:''
    //     });
  };

  const saveEditInformation = async (event) => {
    setNum(num + 1);

    setIsLoadingMore(true);
    if (
      (file !== '' || imagefile !== '') &&
      userInfo.title !== '' &&
      userInfo.description !== '' &&
      userInfo.category !== ''
    ) {
      const data = {
        _id: params.id,
        image: file ? file : imagefile,
        title: userInfo.title,
        description: userInfo.description,
        video: userInfo.video,
        clientName: userInfo.clientName,
        category: userInfo.category,
        tags: userInfo.tags,
        result: userInfo.result,
        challenge: userInfo.challenge,
        solution: userInfo.solution,
      };
      console.log('userInfofile', userInfo);
      const formData = new FormData();

      Object.keys(data).forEach(function (key) {
        formData.append(key, data[key]);
      });
      console.log('formData', formData);
      GetUpdateCaseStudies(formData).then((response) => {
        if (response.status === true) {
          setIsLoadingMore(false);
          toast.success('Case Studies edit succecfully!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getCaseStudiesId();
          setEditTitle(false);
          setEditTags(false);
          setEditDescription(false);
          setEditChallenge(false);
          setEditSolution(false);
          setEditOutcome(false);
          setIsClientName(false);
          navigate('/allcasestudies');
        }
      });
    } else if (file === '') {
      setIsLoadingMore(false);
      toast.error('Please provide image', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.title === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Title', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.description === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Description', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.category === '') {
      setIsLoadingMore(false);
      toast.error('Please select Category', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoadingMore(false);
      toast.error('Error', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onCategorySelected = (e) => {
    setUserInfo({ ...userInfo, category: e.target.value });
  };
  const handleFileInput = (e) => {
    // const name = e.target.name;
    // const value = e.target.value;
    // setUserInfo({ ...userInfo, [name]: value });
    let file = e.target.files[0];
    e.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;

        if (height === 1140 && width === 585) {
          setFile(file);
          //   setpreview(true);
        } else {
          alert('Image width and height must be 585x1140 px');
          //   toast.error('Image width and height must be 1140x585 px', {
          //     position: "top-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });
          return false;
        }
      };
    };
    reader.onerror = function (error) {};
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };

  useEffect(() => {
    getCaseStudiesId();
  }, []);
  const getCaseStudiesId = async () => {
    GetEditCaseStudies(params.id).then((res) => {
      setUserInfo(res.data.data);
      setimageFile(res.data.data.image);
    });
  };
  return (
    <div className="cases">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <>
          <div className="relative md:ml-64">
            <div className="flex flex-wrap">
              <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <h1 className="addArticle_heading">
                All Fields are mandatory:
              </h1> */}

                    <div style={{ marginTop: '15px' }}>
                      <label className="addArticle_label">Video Link</label>
                      {/* <br></br> */}
                      <input
                        type="text"
                        placeholder="Video Link"
                        name="video"
                        value={
                          userInfo.video === 'undefined' ? '' : userInfo.video
                        }
                        onChange={handleInput}
                        style={styles.inputBox}
                      />
                    </div>

                    <div
                      className="addArticle_label"
                      style={{ marginTop: '10px' }}
                    >
                      Please upload image in the dimension of 585x1140*
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      {/* {console.log("getfile",file && URL.createObjectURL(file))} */}

                      {imagefile && (
                        <img
                          src={file ? URL.createObjectURL(file) : imagefile}
                          alt=""
                          style={{
                            width: 278,
                            height: 235,
                            marginBottom: '10px',
                            border: '2px solid gray',
                          }}
                        />
                      )}

                      <input
                        type="file"
                        name="file"
                        onChange={handleFileInput}
                        id="file"
                        style={{
                          backgroundColor: '#000',
                          height: 60,
                          width: 60,
                          position: 'absolute',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                      <label htmlFor="file">
                        <Button
                          variant="contained"
                          component="span"
                          htmlFor="file"
                          style={{ fontWeight: 'bold' }}
                        >
                          Upload
                          <FileUploadIcon />
                        </Button>{' '}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div style={{ marginTop: '15px' }}>
                    <div className="editIcon__container">
                      <label className="addArticle_label">Title*</label>
                      {editTitle ? (
                        <img
                          onClick={() => setEditTitle(false)}
                          src={closeIcon}
                          alt="edit title"
                          className="editIcon__icon"
                        />
                      ) : (
                        <img
                          onClick={() => setEditTitle(true)}
                          src={editIcon}
                          alt="edit title"
                          className="editIcon__icon"
                        />
                      )}
                    </div>

                    {editTitle ? (
                      <input
                        type="text"
                        placeholder="Title *"
                        name="title"
                        value={userInfo.title}
                        style={styles.inputBox}
                        onChange={handleInput}
                      />
                    ) : (
                      <p>{userInfo.title}</p>
                    )}
                  </div>
                  <hr style={{ margin: '5px 0' }} />
                  {/* <label>Key Words*</label><br/> */}
                  <div style={{ marginTop: '15px' }}>
                    <div className="editIcon__container">
                      <label className="addArticle_label">Description*</label>
                      {editDescription ? (
                        <img
                          onClick={() => setEditDescription(false)}
                          src={closeIcon}
                          alt="edit Description"
                          className="editIcon__icon"
                        />
                      ) : (
                        <img
                          onClick={() => setEditDescription(true)}
                          src={editIcon}
                          alt="edit Description"
                          className="editIcon__icon"
                        />
                      )}
                    </div>
                    {/* <br></br> */}

                    {editDescription ? (
                      <CKEditor
                        editor={ClassicEditor}
                        data={userInfo.description}
                        onChange={(e, editor) =>
                          setUserInfo({
                            ...userInfo,
                            description: editor.getData(),
                          })
                        }
                      />
                    ) : (
                      <p
                        className="updateCaseStudy__p"
                        dangerouslySetInnerHTML={{
                          __html: userInfo.description,
                        }}
                      ></p>
                    )}

                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Description*"
                      name="description"
                      value={userInfo.description}
                      onChange={handleInput}
                      style={{ borderRadius: '4px' }}
                    ></textarea> */}
                  </div>

                  <div>
                    {/* <textarea
                name="story"
                rows="3"
                cols="100"
                id="textbox"
                value={formData.story}
                placeholder="Write the Article Here...     (Min 100 Words) "
                onChange={onFormDataChange}
                style={{ marginTop: 20, borderRadius: '5px' }}
              ></textarea> */}
                  </div>
                  {/* <div style={{ marginTop: '15px' }}>
              <label className="addArticle_label">Challange*</label>
              // <br></br>
              <input
                type="text"
                placeholder="Challange *"
                name="challange"
                value={userInfo.challange}
                style={styles.inputBox}
                onChange={handleInput}
              />
            </div>
            <div style={{ marginTop: '15px' }}>
              <label className="addArticle_label">Solution*</label>
              // <br></br>
              <input
                type="text"
                placeholder="Solution*"
                name="solution"
                value={userInfo.solution}
                style={styles.inputBox}
                onChange={handleInput}
              />
            </div> */}
                  {/* <div style={{ marginTop: '15px' }}>
            <label className="addArticle_label">Result*</label>
              // <br></br>
              <input
                type="text"
                placeholder="Result*"
                name="result"
                value={userInfo.result}
                style={styles.inputBox}
                onChange={handleInput}
              />
            </div> */}
                  <hr style={{ margin: '5px 0' }} />

                  <div style={{ marginTop: '15px' }}>
                    <div className="editIcon__container">
                      {' '}
                      <label className="addArticle_label">
                        Customer Challenge*
                      </label>
                      {editChallenge ? (
                        <img
                          onClick={() => setEditChallenge(false)}
                          src={closeIcon}
                          alt="edit Challenge"
                          className="editIcon__icon"
                        />
                      ) : (
                        <img
                          onClick={() => setEditChallenge(true)}
                          src={editIcon}
                          alt="edit Challenge"
                          className="editIcon__icon"
                        />
                      )}
                    </div>
                    {/* <br></br> */}
                    {editChallenge ? (
                      <CKEditor
                        editor={ClassicEditor}
                        data={userInfo.challenge}
                        onChange={(e, editor) =>
                          setUserInfo({
                            ...userInfo,
                            challenge: editor.getData(),
                          })
                        }
                      />
                    ) : (
                      <p
                        // className="updateCaseStudy__p"
                        dangerouslySetInnerHTML={{
                          __html: userInfo.challenge,
                        }}
                      ></p>
                    )}
                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Challenge*"
                      name="challenge"
                      value={userInfo.challenge}
                      onChange={handleInput}
                      style={{ borderRadius: '4px' }}
                    ></textarea> */}
                  </div>
                  <hr style={{ margin: '5px 0' }} />

                  <div style={{ marginTop: '15px' }}>
                    <div className="editIcon__container">
                      <label className="addArticle_label">SLS Solution*</label>
                      {editSolution ? (
                        <img
                          onClick={() => setEditSolution(false)}
                          src={closeIcon}
                          alt="edit Description"
                          className="editIcon__icon"
                        />
                      ) : (
                        <img
                          onClick={() => setEditSolution(true)}
                          src={editIcon}
                          alt="edit Description"
                          className="editIcon__icon"
                        />
                      )}
                    </div>
                    {/* <br></br> */}
                    {editSolution ? (
                      <CKEditor
                        editor={ClassicEditor}
                        data={userInfo.solution}
                        onChange={(e, editor) =>
                          setUserInfo({
                            ...userInfo,
                            solution: editor.getData(),
                          })
                        }
                      />
                    ) : (
                      <p
                        className="updateCaseStudy__p"
                        dangerouslySetInnerHTML={{
                          __html: userInfo.solution,
                        }}
                      ></p>
                    )}
                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Solution*"
                      name="solution"
                      value={userInfo.solution}
                      onChange={handleInput}
                      style={{ borderRadius: '4px' }}
                    ></textarea> */}
                  </div>
                  <hr style={{ margin: '5px 0' }} />

                  <div style={{ marginTop: '15px' }}>
                    <div className="editIcon__container">
                      {' '}
                      <label className="addArticle_label">Outcome*</label>
                      {editOutcome ? (
                        <img
                          onClick={() => setEditOutcome(false)}
                          src={closeIcon}
                          alt="edit Outcome"
                          className="editIcon__icon"
                        />
                      ) : (
                        <img
                          onClick={() => setEditOutcome(true)}
                          src={editIcon}
                          alt="edit Outcome"
                          className="editIcon__icon"
                        />
                      )}
                    </div>
                    {/* <br></br> */}

                    {editOutcome ? (
                      <CKEditor
                        editor={ClassicEditor}
                        data={userInfo.result}
                        onChange={(e, editor) =>
                          setUserInfo({
                            ...userInfo,
                            result: editor.getData(),
                          })
                        }
                      />
                    ) : (
                      <p
                        className="updateCaseStudy__p"
                        dangerouslySetInnerHTML={{
                          __html: userInfo.result,
                        }}
                      ></p>
                    )}
                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Result*"
                      name="result"
                      value={userInfo.result}
                      onChange={handleInput}
                      style={{ borderRadius: '4px' }}
                    ></textarea> */}
                  </div>
                  <hr style={{ margin: '5px 0' }} />

                  <div style={{ marginTop: '15px' }}>
                    <div className="editIcon__container">
                      <label className="addArticle_label">Name Of Client</label>
                      {isClientName ? (
                        <img
                          onClick={() => setIsClientName(false)}
                          src={closeIcon}
                          alt="edit Description"
                          className="editIcon__icon"
                        />
                      ) : (
                        <img
                          onClick={() => setIsClientName(true)}
                          src={editIcon}
                          alt="edit Description"
                          className="editIcon__icon"
                        />
                      )}
                    </div>
                    {/* <br></br> */}
                    {isClientName ? (
                      <input
                        type="text"
                        placeholder="Name of client"
                        name="clientName"
                        // value={userInfo.clientName}
                        value={
                          userInfo.clientName === 'undefined'
                            ? ''
                            : userInfo.clientName
                        }
                        style={styles.inputBox}
                        onChange={handleInput}
                      />
                    ) : (
                      <p>
                        {userInfo.clientName === 'undefined'
                          ? ''
                          : userInfo.clientName}
                      </p>
                    )}
                  </div>
                  {/* <div style={{ marginTop: '15px' }}>
            <label className="addArticle_label">Location*</label>
              // <br></br>
              <input
                type="text"
                placeholder="Location*"
                name="location"
                value={userInfo.location}
                style={styles.inputBox}
                onChange={handleInput}
              />
            </div> */}
                  <hr style={{ margin: '5px 0' }} />

                  <div style={{ marginTop: '15px' }}>
                    <div className="editIcon__container">
                      <label className="addArticle_label">Tags</label>
                      {editTags ? (
                        <img
                          onClick={() => setEditTags(false)}
                          src={closeIcon}
                          alt="edit title"
                          className="editIcon__icon"
                        />
                      ) : (
                        <img
                          onClick={() => setEditTags(true)}
                          src={editIcon}
                          alt="edit title"
                          className="editIcon__icon"
                        />
                      )}
                    </div>
                    {/* <br></br> */}
                    {editTags ? (
                      <input
                        type="text"
                        placeholder="Tags"
                        name="tags"
                        // value={userInfo.tags}
                        value={
                          userInfo.tags === 'undefined' ? '' : userInfo.tags
                        }
                        style={styles.inputBox}
                        onChange={handleInput}
                      />
                    ) : (
                      <p>
                        {userInfo.tags === 'undefined' ? '' : userInfo.tags}
                      </p>
                    )}
                  </div>
                  <div>
                    <>
                      <select
                        style={{
                          //   marginLeft: 20,
                          border: '2px solid #acb9ce',
                          borderRadius: 10,
                          marginBottom: '1.25rem',
                          marginTop: '0.75rem',
                          width: '160px',
                          height: '47px',
                        }}
                        className="mb-5 mt-3"
                        value={userInfo.category}
                        onChange={onCategorySelected}
                      >
                        <option value="">Category</option>
                        <option value="pharmaceutical">Pharmaceutical</option>
                        <option value="automobile">Automobile</option>
                        <option value="outsourcing">Outsourcing</option>
                        <option value="bFSI">BFSI</option>
                        <option value="executive@">Executive</option>

                        <option value="IT Services">IT Services </option>
                        <option value="Private Sector Bank">
                          Private Sector Bank{' '}
                        </option>
                        <option value="Financial Services">
                          Financial Services
                        </option>
                        <option value="Bank">Bank</option>
                        <option value="Publishing">Publishing</option>
                        <option value="Telecom Services">
                          Telecom Services
                        </option>
                        <option value="media">Media</option>
                        <option value="Automotive">Automotive</option>
                        <option value="IT/ITES">IT/ITES</option>
                        <option value="Petroleum and Refinery">
                          Petroleum and Refinery{' '}
                        </option>

                        {/* {docs.map((author) => (
                    <>
                      <option
                        selectedValue={author && author._id}
                        value={author._id}
                      >
                        {author.name}
                      </option>
                    </>
                  ))} */}
                      </select>
                    </>
                  </div>

                  <div className="col-lg-12" style={{ marginBottom: '40px' }}>
                    <button
                      onClick={saveEditInformation}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                    >
                      Save
                    </button>
                    <button
                      onClick={cancleInformation}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      type="button"
                      style={{ backgroundColor: 'red', marginLeft: '10px' }}
                    >
                      {/* {'Draft Article'} */}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ScaleLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={20}
            />
          </div>
        </>
        <getCaseStudiesList num={num} setNum={setNum} />
      </div>
        <ToastContainer />
    </div>
  );
}
const styles = {
  inputBox: {
    width: '500px',
    height: '30px',
    marginTop: 5,
    borderRadius: '5px',
  },
};

export default UpdateCaseStudies;
