import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { GetUpdateBlogs,GetEditBlog } from "../../handlers/blogs.handlers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";

import '../../assets/style.css'
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
function UpdateSpcialBlogs() {
    const navigate = useNavigate();
    const params = useParams();
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [userInfo, setUserInfo] = useState({
      embedPostLink: "",
    });
  
    const handleInput = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setUserInfo({ ...userInfo, [name]: value });
    };
    const saveInformation = () => {
      setIsLoadingMore(true);
      if (userInfo.embedPostLink !== "") {
        const data = {
          _id: params.id,
          embedPostLink: userInfo.embedPostLink,
        };
  
        GetUpdateBlogs(data).then((response) => {
          if (response.data.status === 200) {
            console.log("case", response.data);
            // alert(`${response.data.message}`);
            setIsLoadingMore(false);
            toast.info(`${response.data.message}`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          //  props.onCloseEdit(true);
            navigate("/allblogs");
          }
        });
      } else if (userInfo.embedPostLink === "") {
        setIsLoadingMore(false);
        toast.error("Please add Embed Post Link", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setIsLoadingMore(false);
        toast.error("Error", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    useEffect(() => {
      getCaseStudiesId();
    }, []);
    const getCaseStudiesId = async () => {
        GetEditBlog(params.id)
      // await axios
      //   .get(
      //     `http://52.250.20.141/silverleaf-backend/api/blogs/getBlogById/${props.id}`
      //   )
        .then((res) => {
          console.log("check");
          console.log("id", res.data.data[0]);
          // setUserInfo(res.data.data[0])
          // setimageFile(res.data.data[0].image)
          console.log("id", res.data.data);
          setUserInfo(res.data.data);
        });
    };
    const cancleInformation = () => {
        navigate('/allblogs')
       
      };
  return (
    <div className="cases">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <>
          <div className="relative md:ml-64">
            <div className="flex flex-wrap">
              <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <h1 className="addArticle_heading">
                All Fields are mandatory:
              </h1> */}

                    <div style={{ marginTop: "20px" }}>
                      <label className="addArticle_label">Add Embed Link</label>
                      <br></br>
                      <textarea
          rows="3"
          cols="100"
          type="text"
          placeholder="Embed Post Link*"
          name="embedPostLink"
          value={userInfo.embedPostLink}
          onChange={handleInput}
          style={{ borderRadius: "4px" }}
        ></textarea>
                    </div>

                    <div style={{ paddingTop: 30, marginLeft: 511 }}>
                   

        <Button
          type="button"
          onClick={saveInformation}
          variant="contained"
          className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
        //   style={{ width: "100px", backgroundColor: "rgb(220,20,60)" }}
        >
          Save
        </Button>
        <button
                      onClick={cancleInformation}
                      className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      type="button"
                      style={{ backgroundColor: "red", marginLeft: "10px" }}
                    >
                      {/* {'Draft Article'} */}
                      Cancel
                    </button>
      </div>
      {<div dangerouslySetInnerHTML={{ __html: userInfo.embedPostLink }} />}   
                  </div>
                </div>
                <div className="row">
                  
                  {/* <label>Key Words*</label><br/> */}
                 
                  
                  <div>
                    <>
                     
                    </>
                  </div>

                 
                </div>
              </div>
            </div>
            <ScaleLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={20}
            />
          </div>
        </>
      </div>
        <ToastContainer />
    </div>
  );
}
const styles = {
  inputBox: {
    width: "500px",
    height: "30px",
    marginTop: 5,
    borderRadius: "5px",
  },
};

export default UpdateSpcialBlogs;
