import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";

export const CreateBlog = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.Blogs.CREATE_Blogs}`,
    body,
    {
      headers,
    }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};
export const GetAllBlogs = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.Blogs.GET_ALL_Blogs}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetDeleteBlog = async (Id) => {
  return await AxiosInstance.delete(
    `${AppConstants.ServerConstants.API_ROUTES.Blogs.DELETE_Blogs}/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};

export const GetUpdateBlogs = async (body) => {
  return await AxiosInstance.put(
    `${AppConstants.ServerConstants.API_ROUTES.Blogs.UPDATE_Blogs}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};


export const GetEditBlog = async (Id) => {
  return await AxiosInstance.get(
    `${AppConstants.ServerConstants.API_ROUTES.Blogs.EDIT_Blogs}/${Id}`
   // `http://52.250.20.141/silverleaf-backend/api/career/deleteJob/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};