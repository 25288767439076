import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const GetALLAward = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.AWARDS.GET_ALL_AWARDS}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetCreateAward = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.AWARDS.CREATE_AWARDS}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetDeleteAwards = async (Id) => {
  return await AxiosInstance.delete(
    `${AppConstants.ServerConstants.API_ROUTES.AWARDS.DELETE_AWARD}/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};
export const GetUpdateAwards = async (body) => {
  return await AxiosInstance.put(
    `${AppConstants.ServerConstants.API_ROUTES.AWARDS.UPDATE_AWARD}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetAwardById = async (Id) => {
  return await AxiosInstance.get(
    `${AppConstants.ServerConstants.API_ROUTES.AWARDS.EDIT_AWARD}/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};
