import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";

export const GetCreateCareer = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.Career.ADD_Career}`,
   // "http://52.250.20.141/silverleaf-backend/api/career/addJobDetails",
    body,
    {
      headers,
    }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};
export const GetAllCareer = async () => {
  return await AxiosInstance.get(
     `${AppConstants.ServerConstants.API_ROUTES.Career.Get_Career}`
   // "http://52.250.20.141/silverleaf-backend/api/career/getJobs"
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetDeleteCareer = async (Id) => {
  return await AxiosInstance.delete(
    `${AppConstants.ServerConstants.API_ROUTES.Career.Delete_Career}/${Id}`
   // `http://52.250.20.141/silverleaf-backend/api/career/deleteJob/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};

export const GetUpdateCareer = async (body) => {
  return await AxiosInstance.put(
    `${AppConstants.ServerConstants.API_ROUTES.Career.Update_Career}`,
   // "http://52.250.20.141/silverleaf-backend/api/career/updateJob",
    body
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetAppliedUser = async () => {
  return await AxiosInstance.get(
    `${AppConstants.ServerConstants.API_ROUTES.Career.LIST_Appplied_User}`,
    //"http://52.250.20.141/silverleaf-backend/api/career/listOfJobAppliedUser"
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};


export const GetEditCareer = async (Id) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.Career.EDIT_Career}/${Id}`
   // `http://52.250.20.141/silverleaf-backend/api/career/deleteJob/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};