import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";

export const GetCreateCaseStudies = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.CASE_STUDY.CREATE_CASE_STUDY}`,
    body,
    {
      headers,
    }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};
export const GetAllCaseStudies = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.CASE_STUDY.GET_ALL_CASE_STUDY}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetDeleteCaseStudies = async (Id) => {
  return await AxiosInstance.delete(
    `${AppConstants.ServerConstants.API_ROUTES.CASE_STUDY.DELETE_CASE_STUDY}/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};
export const GetUpdateCaseStudies = async (body) => {
  return await AxiosInstance.put(
    `${AppConstants.ServerConstants.API_ROUTES.CASE_STUDY.UPDATE_CASE_STUDY}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetEditCaseStudies = async (Id) => {
  return await AxiosInstance.get(
    `${AppConstants.ServerConstants.API_ROUTES.CASE_STUDY.EDIT_CASE_STUDY}/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};