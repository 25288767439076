import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import Button from '@mui/material/Button';
import editIcon from '../../assets/edit.png';
import closeIcon from '../../assets/close.png';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { GetAwardById, GetUpdateAwards } from '../../handlers/awards.handler';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function UpdateAwards() {
  const navigate = useNavigate();
  const params = useParams();
  const [file, setFile] = useState('');
  const [num, setNum] = useState(0);
  const [imagefile, setimageFile] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [userInfo, setUserInfo] = useState({
    file: '',
    title: '',
  });
  const cancleInformation = () => {
    navigate('/awards');
  };

  const saveEditInformation = async (event) => {
    setNum(num + 1);

    setIsLoadingMore(true);
    if ((file !== '' || imagefile !== '') && userInfo.title !== '') {
      const data = {
        _id: params.id,
        image: file ? file : imagefile,
        title: userInfo.title,
      };
      const formData = new FormData();

      Object.keys(data).forEach(function (key) {
        formData.append(key, data[key]);
      });
      console.log('formData', formData);
      GetUpdateAwards(formData).then((response) => {
        if (response.status === true) {
          setIsLoadingMore(false);
          toast.success('Case Studies edit succecfully!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleGetAwardById();
          setEditTitle(false);
          navigate('/awards');
        }
      });
    } else if (file === '') {
      setIsLoadingMore(false);
      toast.error('Please provide image', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.title === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Title', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoadingMore(false);
      toast.error('Error', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleFileInput = (e) => {
    let file = e.target.files[0];
    e.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        // let height = this.height;
        // let width = this.width;

        // if (height === 585 && width === 1140) {
        setFile(file);
        // } else {
        //   alert('Image width and height must be 1140x585 px');
        //   return false;
        // }
      };
    };
    reader.onerror = function (error) {};
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };

  useEffect(() => {
    handleGetAwardById();
  }, []);
  const handleGetAwardById = async () => {
    GetAwardById(params.id).then((res) => {
      setUserInfo(res.data.data);
      setimageFile(res.data.data.image);
    });
  };
  return (
    <div className="cases">
      <Sidebar />
      <div className="newContainer">
        <>
          <div className="relative md:ml-64">
            <div className="flex flex-wrap">
              <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <div
                      className="addArticle_label"
                      style={{ marginTop: '10px' }}
                    >
                      Please upload image in the dimension of 1140x585*
                    </div> */}
                    <div style={{ marginTop: '10px' }}>
                      {imagefile && (
                        <img
                          src={file ? URL.createObjectURL(file) : imagefile}
                          alt=""
                          style={{
                            width: 278,
                            height: 235,
                            marginBottom: '10px',
                            border: '2px solid gray',
                          }}
                        />
                      )}

                      <input
                        type="file"
                        name="file"
                        onChange={handleFileInput}
                        id="file"
                        style={{
                          backgroundColor: '#000',
                          height: 60,
                          width: 60,
                          position: 'absolute',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                      <label htmlFor="file">
                        <Button
                          variant="contained"
                          component="span"
                          htmlFor="file"
                          style={{ fontWeight: 'bold' }}
                        >
                          Upload
                          <FileUploadIcon />
                        </Button>{' '}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div style={{ marginTop: '15px' }}>
                    <div className="editIcon__container">
                      <label className="addArticle_label">Title*</label>
                      {editTitle ? (
                        <img
                          onClick={() => setEditTitle(false)}
                          src={closeIcon}
                          alt="edit title"
                          className="editIcon__icon"
                        />
                      ) : (
                        <img
                          onClick={() => setEditTitle(true)}
                          src={editIcon}
                          alt="edit title"
                          className="editIcon__icon"
                        />
                      )}
                    </div>

                    {editTitle ? (
                      <input
                        type="text"
                        placeholder="Title *"
                        name="title"
                        value={userInfo.title}
                        style={styles.inputBox}
                        onChange={handleInput}
                      />
                    ) : (
                      <p>{userInfo.title}</p>
                    )}
                  </div>
                  <hr style={{ margin: '5px 0' }} />
                  <div className="col-lg-12" style={{ marginBottom: '40px' }}>
                    <button
                      onClick={saveEditInformation}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                    >
                      Save
                    </button>
                    <button
                      onClick={cancleInformation}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      type="button"
                      style={{ backgroundColor: 'red', marginLeft: '10px' }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ScaleLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={20}
            />
          </div>
        </>
        <getCaseStudiesList num={num} setNum={setNum} />
      </div>
        <ToastContainer />
    </div>
  );
}
const styles = {
  inputBox: {
    width: '500px',
    height: '30px',
    marginTop: 5,
    borderRadius: '5px',
  },
};

export default UpdateAwards;
