import React from "react";
import "./sidebar.css";
import { Link, NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";

function Sidebar({ history }) {
  return (
    <React.Fragment>
      <div className="sidebar">
        <div class="wrapper">
          <nav id="sidebar">
            <div className="top">
              {" "}
              <img
                src="https://silverleafsolutions.com/site/wp-content/uploads/2018/09/logo.png"
                alt=""
              />
            </div>
            <hr />
            <div className="center" style={{ marginTop: "30px" }}>
              <ul className="navbar">
                {/* <p className='title'>MAIN</p> */}
                {/* <Link to="/list" style={{textDecoration:"none"}}>
      <li>
        <span>Slider List</span>
      
      </li>
      </Link> */}
                <li style={{ height: "43px" }}>
                  <NavLink className="nav-link" to="/banners">
                    <HomeIcon style={{ color: "black", marginRight: "5px" }} />
                    <span style={{ color: "black" }}>Home Page Banner</span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/addcasestudies"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Add Case Studies</span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/allcasestudies"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Case Studies</span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/addpartnerwithus"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>All Partner </span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/createCareer"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Add career</span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/allcareer"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Careers List</span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/awards"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Awards</span>
                  </NavLink>
                </li>
                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/addAward"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Add Award</span>
                  </NavLink>
                </li>
                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/createBlog"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Add Blog</span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/allblogs"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Blog List</span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/gallery"
                    style={{ textDecoration: "none" }}
                  >
                    <DashboardIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>Gallery</span>
                  </NavLink>
                </li>

                <li style={{ height: "43px" }}>
                  <NavLink
                    className="nav-link"
                    to="/silverleaf-adminpanel"
                    style={{ textDecoration: "none" }}
                  >
                    <LogoutIcon
                      style={{ color: "black", marginRight: "5px" }}
                    />
                    <span style={{ color: "black" }}>LogOut</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>

          <nav class="navbar navbar-default">
            <div class="container-fluid">
              <div class="navbar-header">
                <button
                  type="button"
                  id="sidebarCollapse"
                  class="btn btn-info navbar-btn"
                >
                  <i class="glyphicon glyphicon-align-left"></i>
                  Toggel
                </button>
              </div>
            </div>
          </nav>
        </div>
        {/* <div className="bottom">color</div> */}
      </div>
      <script src="https://code.jquery.com/jquery-1.12.0.min.js"></script>

      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
      <script type="text/javascript">
        {/* $(document).ready(function () {
        $('#sidebarCollapse').on('click', function () {
            $('#sidebar').toggleClass('active');
        })
    }) */}
      </script>
    </React.Fragment>
  );
}

export default Sidebar;
