import React,{useEffect, useState} from 'react'
import Modal from "react-modal";
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import axios from 'axios';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import {EditPartnerWithUs} from "../../handlers/PartnerWithUs.handler"
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function OpenDetailsPage(props) {
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [userInfo, setUserInfo] = useState({
        file:'',
        title: '',
        description: '',
        challenge:'',
        solution:'',
        result:'',
        client:'',
        location:'',
        category:"",
        tags:'',
        video:''
      });
     
      useEffect(() => {
        getCaseStudiesId()
      }, []);
  const getCaseStudiesId = async()=>{
       setIsLoadingMore(true);
       EditPartnerWithUs(props.id).
  //  await axios.get(`http://52.250.20.141/silverleaf-backend/api/partnerWithUs/getPartnerById/${props.id}`).
    then ((res)=>{
       
        setUserInfo(res.data.data)
          setIsLoadingMore(false);
       
     })
    
   
    }
  return (
    <div>
          <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
              <div style={{width:"100%", backgroundColor:"#1976d2", height:"80px", color:"white", textAlign: "center", paddingTop:"19px", fontSize:"29px"}}> Partner Details</div>

<div style={{display:"flex" , flexDirection:"row"}}>
   <div style={{display:"flex" , flexDirection:"column"}}>
        <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }} >
          <label className="addArticle_label">First Name</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "87px"}}>
            {userInfo && userInfo.firstName}
          </Typography>
      </CardContent>

        <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Email</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "129px"}}>
           {userInfo && userInfo.email}
          </Typography>
      </CardContent>

         <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Company Name</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "56px"}}>
            {userInfo && userInfo.companyName === "undefined" ? "NA" : userInfo.companyName}
          </Typography>
      </CardContent>

      <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Country</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "115px"}}>
            {userInfo && userInfo.country === "undefined" ? "NA" : userInfo.country}
          </Typography>
      </CardContent>
      <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">City</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "147px"}}>
            {userInfo && userInfo.city === "undefined" ? "NA" : userInfo.city}
          </Typography>
      </CardContent>

       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Company Revenue</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "45px"}}>
            {userInfo && userInfo.companyRevenue === "undefined" ? "NA" : userInfo.companyRevenue}
          </Typography>
      </CardContent>

        <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Employees</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "100px"}}>
            {userInfo && userInfo.employees === "undefined" ? "NA" : userInfo.employees}
          </Typography>
      </CardContent>
   </div>
   <div style={{display:"flex" , flexDirection:"column"}}>
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }} >
          <label className="addArticle_label">Last Name</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "87px"}}>
           {userInfo && userInfo.lastName}
          </Typography>
      </CardContent>
      <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row' , marginRight:"130px" }}>
          <label className="addArticle_label">Phone Number</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "58px"}}>
           {userInfo && userInfo.phoneNumber}
          </Typography>
      </CardContent>

       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Type Of Business</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "52px"}}>
            {userInfo && userInfo.typeOfBusiness === "undefined" ? "NA" : userInfo.typeOfBusiness}
          </Typography>
      </CardContent>

      
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">State</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "137px"}}>
            {userInfo && userInfo.state === "undefined" ? "NA" : userInfo.state}
          </Typography>
      </CardContent>

       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Address</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "115px"}}>
            {userInfo && userInfo.address === "undefined" ? "NA" : userInfo.address}
          </Typography>
      </CardContent>

       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Currency</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "115px"}}>
            {userInfo && userInfo.currency === "undefined" ? "NA" : userInfo.currency}
          </Typography>
      </CardContent>
        <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">URL</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "147px"}}>
            {userInfo && userInfo.url === "undefined" ? "NA" : userInfo.url}
          </Typography>
      </CardContent>
   </div>

</div>
       <Grid container justify="center">
             <Button
onClick={props.onClosePartner}
                variant="contained" 
                style={{marginLeft: "672px"}}
              >
              Close
              </Button></Grid> 
            </Modal>
             <ScaleLoader
          color="#23A5E0"
          loading={isLoadingMore}
          css={override}
          size={20}
        />
            </div>
  )
}

export default OpenDetailsPage