import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Button from "@mui/material/Button";
// import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetUpdateBlogs,GetEditBlog } from "../../handlers/blogs.handlers";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const UpdateSocialMediaLink = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [userInfo, setUserInfo] = useState({
    embedPostLink: "",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const saveInformation = () => {
    setIsLoadingMore(true);
    if (userInfo.embedPostLink !== "") {
      const data = {
        _id: props.id,
        embedPostLink: userInfo.embedPostLink,
      };

      GetUpdateBlogs(data).then((response) => {
        if (response.data.status === 200) {
          console.log("case", response.data);
          // alert(`${response.data.message}`);
          setIsLoadingMore(false);
          toast.info(`${response.data.message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.onCloseEdit(true);
          navigate("/allblogs");
        }
      });
    } else if (userInfo.embedPostLink === "") {
      setIsLoadingMore(false);
      toast.error("Please add Embed Post Link", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoadingMore(false);
      toast.error("Error", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {
    getCaseStudiesId();
  }, []);
  const getCaseStudiesId = async () => {
    GetEditBlog(props.id)
    // await axios
    //   .get(
    //     `http://52.250.20.141/silverleaf-backend/api/blogs/getBlogById/${props.id}`
    //   )
      .then((res) => {
        console.log("check");
        console.log("id", res.data.data[0]);
        // setUserInfo(res.data.data[0])
        // setimageFile(res.data.data[0].image)
        console.log("id", res.data.data);
        setUserInfo(res.data.data);
      });
  };

  return (
    <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
      <div style={{ marginTop: "20px" }}>
        <label className="addArticle_label">Add Embed Link</label>
        <br></br>
        {/* <input
          type="text"
          placeholder="embedPostLink *"
          name="embedPostLink"
          value={userInfo.embedPostLink}
          onChange={handleInput}
        /> */}
        <textarea
          rows="3"
          cols="100"
          type="text"
          placeholder="Embed Post Link*"
          name="embedPostLink"
          value={userInfo.embedPostLink}
          onChange={handleInput}
          style={{ borderRadius: "4px" }}
        ></textarea>
      </div>
      <div style={{ paddingTop: 30, marginLeft: 511 }}>
        {props.onCloseEdit && (
          <Button
            type="button"
            onClick={props.onCloseEdit}
            variant="contained"
            style={{
              width: "100px",
              marginRight: "27px",
              backgroundColor: "green",
            }}
          >
            Cancel
          </Button>
        )}

        <Button
          type="button"
          onClick={saveInformation}
          variant="contained"
          style={{ width: "100px", backgroundColor: "rgb(220,20,60)" }}
        >
          Save
        </Button>
      </div>
      {<div dangerouslySetInnerHTML={{ __html: userInfo.embedPostLink }} />}
      {/* {userInfo && <>{userInfo.embedPostLink}</>} */}
        <ToastContainer />
    </Modal>
  );
};

export default UpdateSocialMediaLink;
