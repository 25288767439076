import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const adminLogin = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.Login.login}`,
    body,
    {
      headers,
    }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};