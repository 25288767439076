import React from "react";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import List from "./pages/list/getCaseStudiesList";
import UpdateCaseStudies from "./pages/list/UpdateCaseStudies";
import Single from "./pages/single/AddCaseStudies";
import CreateCareer from "./pages/Career/CreateCareer";
import EditCareer from "./pages/Career/EditCareer";
import Banners from "./pages/new/Banners";
import AddPartnerWithUs from "./pages/Partner_With_Us/AddPartnerWithUs";
import ViewPartnerwithUs from "./pages/Partner_With_Us/ViewPartnerwithUs";
import AllCareer from "./pages/Career/AllCareer";
import ApplyPositionList from "./pages/Career/ApplyPositionList";
import AllBlogs from "./pages/Blogs/AllBlogs";
import UpdateBlogs from "./pages/Blogs/UpdateBlogs";
import UpdateSocialMediaLink from "./pages/Blogs/UpdateSocialMediaLink";
import UpdateSocialBlogs from "./pages/Blogs/UpdateSocialBlog";
import AddSocialMediaLinkBlog from "./pages/Blogs/AddBlog";
import AddSocialMediaLinkAward from "./pages/Blogs/AddAward";
import Awards from "./pages/Awards/Awards";
import UpdateAwards from "./pages/Awards/UpdateAwards";
import Gallery from "./pages/gallery/Gallery";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="/silverleaf-adminpanel" element={<Login />} />
            <Route path="/banners" element={<Banners />} />
            <Route path="/addcasestudies" element={<Single />} />
            <Route path="/allcasestudies" element={<List />} />
            <Route
              path="/updateCaseStudies/:id"
              element={<UpdateCaseStudies />}
            />
            <Route path="/addpartnerwithus" element={<AddPartnerWithUs />} />
            <Route
              path="/viewpartnerwithus/:id"
              element={<ViewPartnerwithUs />}
            />
            <Route path="/createCareer" element={<CreateCareer />} />
            <Route path="/allcareer" element={<AllCareer />} />
            <Route path="/editcareer/:id" element={<EditCareer />} />
            <Route
              path="/applypositionlist/:role"
              element={<ApplyPositionList />}
            />

            <Route path="/gallery" element={<Gallery />} />
            <Route path="/awards" element={<Awards />} />
            <Route path="/addAward" element={<AddSocialMediaLinkAward />} />
            <Route path="/updateAward/:id" element={<UpdateAwards />} />
            <Route path="/createBlog" element={<AddSocialMediaLinkBlog />} />
            <Route path="/allblogs" element={<AllBlogs />} />
            <Route path="/updateblogs/:id" element={<UpdateBlogs />} />
            <Route
              path="/updateSocialMediaLink/:id"
              element={<UpdateSocialMediaLink />}
            />
            <Route
              path="/updateSocialBlogs/:id"
              element={<UpdateSocialBlogs />}
            />
            <Route path="users"></Route>
            <Route path="products">
              <Route index element={<List />} />
              <Route path=":productId" element={<Single />} />
              <Route path="banners" element={<Banners />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <Home/> */}
    </div>
  );
}

export default App;
