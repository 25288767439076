import React, { useState, useEffect } from 'react';
import '../list/list.css';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableCell } from '@mui/material';
import { TableBody } from '@mui/material';
import { Table } from '@mui/material';
import { TableContainer } from '@mui/material';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import EditIcon from '@mui/icons-material/Edit';
import Single from '../single/AddCaseStudies';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { GetAllBlogs, GetDeleteBlog } from '../../handlers/blogs.handlers';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReactHtmlParser from 'html-react-parser';
import UpdateSocialMediaLink from './UpdateSocialMediaLink';
import { useParams, useNavigate } from 'react-router-dom';
import UpdateSpcialBlogs from './UpdateSocialBlog';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function AllBlogs() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [caseId, setCaseId] = useState('');
  const [editId, setEditId] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const [editModal, setEditModal] = useState({
    show: false,
    id: '',
  });
  const openUpdate = '';

  useEffect(() => {
    getCaseStudy();
  }, [page]);

  const handlePageClick = (event, newPage) => {
    setPage(newPage);
  };
  const getCaseStudy = () => {
    setIsLoadingMore(true);
    const data = {
      page: page,
      limit: 6,
    };
    GetAllBlogs(data).then((response) => {
      if (response.message === 'success') {
        setData(response.data.data.blogList);
        setCount(response.data.data.totalPages);
        setIsLoadingMore(false);
      } else alert('Something went wrong');
    });
  };
  const handleEditClickAll = (id, row, category) => {
    if (category) {
      navigate(`/updateblogs/${id}`);
    } else if (row) {
      navigate(`/updateSocialBlogs/${id}`);
      // handleEditClick(id);
    }
  };
  const handleEditClick = (idedit) => {
    setEditId(idedit);
    showEditModal(idedit);
  };
  const showEditModal = (ids) => {
    let id = ids;
    setEditModal({
      show: true,
      id: id,
    });
  };
  const onCloseEdit = () => {
    setEditModal({
      show: false,
      id: '',
    });
  };
  const handleDeleteClick = (id) => {
    setCaseId(id);
    showModalForPublish();
  };
  const showModalForPublish = () => {
    let title = 'Delete Blog?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    setIsLoadingMore(true);
    GetDeleteBlog(caseId).then((response) => {
      if (response.status === true) {
        setIsLoadingMore(false);
        toast.success('Blog deleted succecfully!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setConfirmationModal({
          message: '',
          show: false,
          title: '',
        });
        getCaseStudy();
      }
    });
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {confirmationModal.show && (
          <ConfirmationModal
            message={confirmationModal.message}
            title={confirmationModal.title}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}

        {editModal.show && (
          <UpdateSocialMediaLink onCloseEdit={onCloseEdit} id={editModal.id} />
        )}
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                {/* <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                >
                  Image
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                >
                  Title
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                >
                  Description
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                >
                  Category
                </th>{" "}
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                >
                  createdBy
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                >
                  Video Link
                </th> */}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Embed Post Link / Title
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((row) => (
                  <tr>
                    {/* <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                     
                      {row && row.image ? (
                        <div
                          style={{
                            background: `url('${row.image}?${row._id}') 0% 0% / cover no-repeat`,
                            width: "100px",
                            height: "53px",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      ) : (
                        "N/A"
                      )}
                    </th> */}
                    {/* <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: "138px" }}
                    >
                      {row && row.title == "undefined"
                        ? "N/A"
                        : row.title
                        ? row.title
                        : "N/A"}
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: "400px" }}
                    >
                     
                      {row && row.description && row.description == "undefined"
                        ? "N/A"
                        : row &&
                          row.description &&
                          ReactHtmlParser(row.description.slice(0, 65) + "...")
                        ? row &&
                          row.description &&
                          ReactHtmlParser(row.description.slice(0, 65) + "...")
                        : "N/A"}
                    </td>

                    <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    
                      {row && row.category == "undefined"
                        ? "N/A"
                        : row.category
                        ? row.category
                        : "N/A"}
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: "104px" }}
                    >
                      {row && row.createdBy == "undefined"
                        ? "N/A"
                        : row.createdBy
                        ? row.createdBy
                        : "N/A"}
                    </td>

                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: "104px" }}
                    >
                      {row && row.video == "undefined"
                        ? "N/A"
                        : row.video
                        ? row.video.slice(0, 10) + "..."
                        : "N/A"}
                    </td> */}
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '104px' }}
                    >
                      {
                        row && row.embedPostLink == undefined
                          ? row.title
                          : row.embedPostLink
                        // ? row.embedPostLink.slice(0, 10) + "..."
                        // : "N/A"
                      }
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '133px' }}
                    >
                      <EditIcon
                        onClick={() => {
                          handleEditClickAll(
                            row._id,
                            row.embedPostLink,
                            row.category
                          );
                        }}
                        style={{ cursor: 'pointer', marginRight: '22px' }}
                      />
                      {/* {console.log("row.embedPostLink", row.embedPostLink)}
                      {row && row.embedPostLink == !"" ? (
                        // <Link to={`/updateSocialMediaLink/${row._id}`}>
                        <EditIcon
                          onClick={() => {
                            handleEditClick(row._id, row.embedPostLink);
                          }}
                          style={{ cursor: "pointer", marginRight: "22px" }}
                        />
                      ) : (
                        <Link to={`/updateblogs/${row._id}`}>
                          <EditIcon
                            style={{ cursor: "pointer", marginRight: "22px" }}
                          />
                        </Link>
                      )} */}

                      <DeleteIcon
                        onClick={() => {
                          handleDeleteClick(row._id);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Stack style={{ alignItems: 'center', margin: '19px' }}>
          <Pagination
            page={page}
            onChange={handlePageClick}
            count={count}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </Stack>
      </div>
      <div>
        <ScaleLoader
          color="#23A5E0"
          loading={isLoadingMore}
          css={override}
          size={20}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default AllBlogs;
