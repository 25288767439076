import React, { useState, useEffect } from 'react';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableCell } from '@mui/material';
import { TableBody } from '@mui/material';
import { Table } from '@mui/material';
import { TableContainer } from '@mui/material';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import EditIcon from '@mui/icons-material/Edit';
import Single from '../single/AddCaseStudies';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {GetAllPartnerWithUs,GetDeletePartnerWithUs} from '../../handlers/PartnerWithUs.handler'
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import Button from '@mui/material/Button';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import OpenDetailsPage from './OpenDetailsPage';


const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function AddPartnerWithUs() {
   
  const [data, setData] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [caseId, setCaseId] = useState('');
  const [partnerId , setPartnerId] = useState('');
   const [count, setCount] = useState(0);
   const [page, setPage] = React.useState(1);
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const [openPartnerDetails, setOpenPartnerDetails] = useState({
    show: false,
    id:""
  })
  const openUpdate = '';

  useEffect(() => {
    getCaseStudy()
  }, [page]);
   const handlePageClick = (event, newPage) => {
    setPage(newPage);
    console.log(page);
  };
  const getCaseStudy = () => {
    setIsLoadingMore(true);
    const data = {
      page: page,
      limit: 8,
    };
    GetAllPartnerWithUs(data).then((response) => {
      if (response.message === 'success') {
        setData(response.data.data.partnerList);
          setCount(response.data.data.totalPages);
        console.log("response.data.data",response.data.data)
        setIsLoadingMore(false);
      } else alert('Something went wrong');
    });
  }
  const handleDeleteClick = (id) =>{
   setCaseId(id)
    showModalForPublish();
  }
  const showModalForPublish = () => {
    let title = 'Delete Partner?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
const partnerDetail = (id) =>{
setPartnerId(id)
showUserDaetails(id)
}

const showUserDaetails = (_id) =>{
  setOpenPartnerDetails({
    show:true,
    id:_id
  })
}
const onClosePartner = () =>{
  setOpenPartnerDetails({
    show:false,
    id:''
  })
}
  const onSuccess = () => {
    setIsLoadingMore(true);
    GetDeletePartnerWithUs(caseId).then((response) => {
      if (response.status === true) {
        console.log("donecase")
        setIsLoadingMore(false);
        toast.success('Partner deleted succecfully!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        setConfirmationModal({
          message: '',
          show: false,
          title: '',
        });
         getCaseStudy()
      }
    });
  };


  return (
    <div className="list">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {confirmationModal.show && (
          <ConfirmationModal
            message={confirmationModal.message}
            title={confirmationModal.title}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
        {
          openPartnerDetails.show && (
            <OpenDetailsPage 
            id={openPartnerDetails.id}
            onClosePartner={onClosePartner}
            />
          )
        }
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  First Name
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Last Name
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Email
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Phone Number
                </th>
                {/* <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Company Name
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Type of Business
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Country
                </th> <th
                 className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  State
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  City
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
               Address
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Company Name
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Currency
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                Employess
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                 URl
                </th> */}
                 <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                 Action
                </th>
               
              </tr>

            </thead>
            <tbody>
              
              {
                data && data.map((row)=>(
                  
                  <tr>
                  
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"138px"}}>
                    {row.firstName }
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"400px"}}>
                   {row.lastName }
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"400px"}}>
                   {row.email }
                    
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"400px"}}>
                  {row.phoneNumber }
                  </td>
                  {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"400px"}}>
                     {row &&row.companyName=="undefined"? 'N/A' : row.companyName  ? row.companyName : 'N/A' }
                 
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {row &&row.typeOfBusiness=="undefined"? 'N/A' : row.typeOfBusiness  ? row.typeOfBusiness : 'N/A' }
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"104px"}}>
                     {row &&row.country=="undefined"? 'N/A' : row.country  ? row.country : 'N/A' }
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"  style={{width:"104px"}}>
                  {row &&row.state=="undefined"? 'N/A' : row.state  ? row.state : 'N/A' }
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"  style={{width:"104px"}}>
                       {row &&row.city=="undefined"? 'N/A' : row.city  ? row.city : 'N/A' }
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"  style={{width:"104px"}}>
                       {row &&row.address=="undefined"? 'N/A' : row.address  ? row.address : 'N/A' }
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"  style={{width:"104px"}}>
                       {row &&row.companyRevenue=="undefined"? 'N/A' : row.companyRevenue  ? row.companyRevenue : 'N/A' }
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"  style={{width:"104px"}}>
                       {row &&row.currency=="undefined"? 'N/A' : row.currency  ? row.currency : 'N/A' }
                  </td>
                   <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"  style={{width:"104px"}}>
                       {row &&row.employees=="undefined"? 'N/A' : row.employees  ? row.employees : 'N/A' }
                  </td>
                   <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"  style={{width:"104px"}}>
                       {row &&row.url=="undefined"? 'N/A' : row.url  ? row.url : 'N/A' }
                  </td> */}
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"274px", display:"flex", alignItems:"center", marginLeft:"-55px"}}>
                 
                 {/* <Link to={`/viewpartnerwithus/${row._id}`}>
                 
              <Button variant="contained" style={{ cursor: 'pointer',marginRight:'22px' }}>View More</Button>
              </Link> */}

            
                 
              <Button variant="contained" style={{ cursor: 'pointer',marginRight:'22px' }}  onClick={() => {
                  partnerDetail(row._id);
                }}>View More</Button>
             
              <DeleteIcon
                onClick={() => {
                  handleDeleteClick(row._id);
                }}
                style={{ cursor: 'pointer' }}
              />
                  </td>

                  </tr>
                ))
              }
             
            </tbody>
            </table>
            </div>
   <Stack style={{ alignItems: "center", margin: "19px" }}>
          <Pagination
            page={page}
            onChange={handlePageClick}
            count={count}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </Stack>
      </div>
      <div >
        <ScaleLoader
          color="#23A5E0"
          loading={isLoadingMore}
          css={override}
          size={20}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddPartnerWithUs;
