import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const GetCreateBanner = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.BANNERS.CREATE_BANNER}`,
    body,
    {
      headers,
    }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};

export const GetAllBanners = async () => {
  return await AxiosInstance.get(
    `${AppConstants.ServerConstants.API_ROUTES.BANNERS.GET_ALL_BANNERS}`
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetDeleteBanner = async (bannerId) => {
  return await AxiosInstance.delete(
    `${AppConstants.ServerConstants.API_ROUTES.BANNERS.DELETE_BANNER}/${bannerId}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};

export const GetUpdateBanner = async (body) => {
  return await AxiosInstance.put(
    `${AppConstants.ServerConstants.API_ROUTES.BANNERS.UPDATE_BANNER}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
