import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";

export const GetAllGallery = async () => {
  return await AxiosInstance.get(
    `${AppConstants.ServerConstants.API_ROUTES.Gallery.Get_All_Gallery}`
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetCreateGallery = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.Gallery.ADD_Gallery}`,
    body,
    {
      headers,
    }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};

export const GetEditGallery = async (body) => {
  return await AxiosInstance.put(
    `${AppConstants.ServerConstants.API_ROUTES.Gallery.EDIT_Gallery}`,
    body
    // { headers }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};

export const GetDeleteGallery = async (Id) => {
  return await AxiosInstance.delete(
    `${AppConstants.ServerConstants.API_ROUTES.Gallery.Delete_Gallery}/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};
