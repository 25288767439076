import React,{useState,useEffect} from 'react';
import { useParams ,useNavigate} from "react-router-dom"
import axios from 'axios';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {GetUpdateCaseStudies} from '../../handlers/caseStudies.handler'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {EditPartnerWithUs} from "../../handlers/PartnerWithUs.handler"

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function ViewPartnerwithUs() {
    const navigate = useNavigate();
    const params = useParams()
    //  const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [userInfo, setUserInfo] = useState({
        file:'',
        title: '',
        description: '',
        challenge:'',
        solution:'',
        result:'',
        client:'',
        location:'',
        category:"",
        tags:'',
        video:''
      });
     
      useEffect(() => {
        getCaseStudiesId()
      }, []);
  const getCaseStudiesId = async()=>{
    EditPartnerWithUs(params.id).
  //  await axios.get(`http://52.250.20.141/silverleaf-backend/api/partnerWithUs/getPartnerById/${params.id}`).
    then ((res)=>{
        console.log("check")
        console.log("id",res.data.data[0])
        // setUserInfo(res.data.data[0])
        // setimageFile(res.data.data[0].image)
        console.log("id",res.data.data)
        setUserInfo(res.data.data)
        
       
     })
    
   
    }

    const backButton = ()=>{
      navigate("/addpartnerwithus")
    }
  return (
    <div className='cases'>
    <Sidebar />
    <div className="newContainer">
    <Navbar />
    <>
     <Card sx={{ display: 'flex' }} style={{marginLeft:"auto",marginRight:"auto", marginTop:"30px",display: 'flex', flexDirection: 'column',marginBottom:"50px", width:"80%", border:"1px solid" }}>
     <div style={{display:"flex",borderBottom: "1px solid", marginLeft: "7px",marginRight: "7px"}}>
      <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">First Name</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "87px"}}>
            {userInfo && userInfo.firstName}
          </Typography>
      </CardContent>
      {/* <div style={{borderBottom: "1px solid", marginLeft: "7px",marginRight: "7px"}}></div> */}
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Last Name</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "87px"}}>
           {userInfo && userInfo.lastName}
          </Typography>
      </CardContent>
    </div>
    <div style={{display:"flex",borderBottom: "1px solid", marginLeft: "7px",marginRight: "7px"}}>
    
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Email</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "129px"}}>
           {userInfo && userInfo.email}
          </Typography>
      </CardContent>

    <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row' , marginRight:"130px" }}>
          <label className="addArticle_label">Phone Number</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "58px"}}>
           {userInfo && userInfo.phoneNumber}
          </Typography>
      </CardContent>
</div>
      <div style={{display:"flex",borderBottom: "1px solid", marginLeft: "7px",marginRight: "7px"}}>
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Company Name</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "56px"}}>
            {userInfo && userInfo.companyName === "undefined" ? "NA" : userInfo.companyName}
          </Typography>
      </CardContent>

      
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Type Of Business</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "52px"}}>
            {userInfo && userInfo.typeOfBusiness === "undefined" ? "NA" : userInfo.typeOfBusiness}
          </Typography>
      </CardContent>
</div>
      <div style={{display:"flex",borderBottom: "1px solid", marginLeft: "7px",marginRight: "-61px"}}>
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Country</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "115px"}}>
            {userInfo && userInfo.country === "undefined" ? "NA" : userInfo.country}
          </Typography>
      </CardContent>

  
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">State</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "137px"}}>
            {userInfo && userInfo.state === "undefined" ? "NA" : userInfo.state}
          </Typography>
      </CardContent>
</div>
      <div style={{display:"flex",borderBottom: "1px solid", marginLeft: "7px",marginRight: "-72px"}}>
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">City</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "147px"}}>
            {userInfo && userInfo.city === "undefined" ? "NA" : userInfo.city}
          </Typography>
      </CardContent>

      
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Address</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "115px"}}>
            {userInfo && userInfo.address === "undefined" ? "NA" : userInfo.address}
          </Typography>
      </CardContent>
</div>
      <div style={{display:"flex",borderBottom: "1px solid", marginLeft: "7px",marginRight: "7px"}}>
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Company Revenue</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "45px"}}>
            {userInfo && userInfo.companyRevenue === "undefined" ? "NA" : userInfo.companyRevenue}
          </Typography>
      </CardContent>

       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Currency</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "115px"}}>
            {userInfo && userInfo.currency === "undefined" ? "NA" : userInfo.currency}
          </Typography>
      </CardContent>
</div>
        <div style={{display:"flex"}}>
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">Employees</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "100px"}}>
            {userInfo && userInfo.employees === "undefined" ? "NA" : userInfo.employees}
          </Typography>
      </CardContent>

   
       <CardContent sx={{ flex: '1 0 auto'}}  style={{display: 'flex', flexDirection: 'row'  }}>
          <label className="addArticle_label">URL</label>
          <Typography variant="body2" color="text.secondary" component="div" style={{fontSize: "16px",
    fontWeight: "bold",marginLeft: "147px"}}>
            {userInfo && userInfo.url === "undefined" ? "NA" : userInfo.url}
          </Typography>
      </CardContent>
      </div>
     </Card>
<Button
onClick={backButton}
                variant="contained" 
                style={{marginLeft:"1010px"}}
              >
              <ArrowBackIcon/> Back
              </Button>
    {/* <div className="relative md:ml-64" >
      <div className="flex flex-wrap">
        <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
          
          <div className="row">
            <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label">First Name*</label>
              <br></br>
              <input
                type="text"
                
                name="firstName"
                value={userInfo.firstName}
                style={styles.inputBox}
               
              />
            </div>
             <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label">Last Name*</label>
              <br></br>
              <input
                type="text"
                
                name="lastName"
                value={userInfo.lastName}
                style={styles.inputBox}
               
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label">Email*</label>
              <br></br>
              <input
                type="text"
                name="email"
                value={userInfo.email}
                style={styles.inputBox}
               
              />
            </div>
              <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label">Phone Number*</label>
              <br></br>
              <input
                type="text"
                name="phoneNumber"
                value={userInfo.phoneNumber}
                style={styles.inputBox}
               
              />
            </div>
             <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label">Company Name</label>
              <br></br>
              <input
                type="text"
                name="companyName"
                value={userInfo.companyName === "undefined" ? "" : userInfo.companyName}
                style={styles.inputBox}
               
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">Type Of Business</label>
              <br></br>
              <input
                type="text"
                placeholder="Name of client"
                name="typeOfBusiness"
                value={userInfo.typeOfBusiness === "undefined" ? "" : userInfo.typeOfBusiness}
                style={styles.inputBox}
               
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">Location*</label>
              <br></br>
              <input
                type="text"
                placeholder="Location*"
                name="location"
                value={userInfo.location}
                style={styles.inputBox}
                onChange={handleInput}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">Country</label>
              <br></br>
              <input
                type="text"
              name="country"
                value={userInfo.country === "undefined" ? "" : userInfo.country}
                style={styles.inputBox}
                
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">State</label>
              <br></br>
              <input
                type="text"
              name="state"
                value={userInfo.state === "undefined" ? "" : userInfo.state}
                style={styles.inputBox}
                
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">City</label>
              <br></br>
              <input
                type="text"
              name="city"
                value={userInfo.city === "undefined" ? "" : userInfo.city}
                style={styles.inputBox}
                
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">Address</label>
              <br></br>
              <input
                type="text"
              name="address"
                value={userInfo.address === "undefined" ? "" : userInfo.address}
                style={styles.inputBox}
                
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">Company Revenue</label>
              <br></br>
              <input
                type="text"
              name="companyRevenue"
                value={userInfo.companyRevenue === "undefined" ? "" : userInfo.companyRevenue}
                style={styles.inputBox}
                
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">Currency</label>
              <br></br>
              <input
                type="text"
              name="currency"
                value={userInfo.currency === "undefined" ? "" : userInfo.currency}
                style={styles.inputBox}
                
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">Employees</label>
              <br></br>
              <input
                type="text"
              name="employees"
                value={userInfo.employees === "undefined" ? "" : userInfo.employees}
                style={styles.inputBox}
                
              />
            </div>
            <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">URL</label>
              <br></br>
              <input
                type="text"
              name="currency"
                value={userInfo.url === "undefined" ? "" : userInfo.url}
                style={styles.inputBox}
                
              />
            </div>
           

           
          </div>
        </div>
      </div>
      <ScaleLoader
        color="#23A5E0"
        loading={isLoadingMore}
        css={override}
        size={20}
      />
    </div> */}
    
  </>


    </div>
      <ToastContainer />
  </div>
  )
}
const styles = {
    inputBox: {
      width: '500px',
      height: '30px',
      marginTop: 5,
      borderRadius: '5px',
    },
  };

export default ViewPartnerwithUs