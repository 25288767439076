import React from 'react'
import "./navbar.css"

function Navbar() {
  return (
    <div className='navbar'>
    <div className='wrapper'></div>
    </div>
  )
}

export default Navbar