import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import './AddCaseStudies.css';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Button from '@mui/material/Button';
import ReactQuill from 'react-quill';
import { GetCreateCaseStudies } from '../../handlers/caseStudies.handler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import { ContentCutOutlined } from '@mui/icons-material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../assets/style.css';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function AddCaseStudies() {
  const [file, setFile] = useState('');
  const [catetory, setcatetory] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [userInfo, setUserInfo] = useState({
    file: '',
    title: '',
    // description: '',
    // challenge: '',
    // solution: '',
    // result: '',
    client: '',
    location: '',
    catrgoryId: '',
    tags: '',
    youtube: '',
  });
  const [description, setDescription] = useState('');
  const [challenge, setChallenge] = useState('');
  const [solution, setSolution] = useState('');
  const [result, setResult] = useState('');

  useEffect(() => {}, [catetory]);
  const onCategorySelected = (e) => {
    setUserInfo({ ...userInfo, catrgoryId: e.target.value });
  };
  const handleFileInput = (e) => {
    // const name = e.target.name;
    // const value = e.target.value;
    // setUserInfo({ ...userInfo, [name]: value });
    let file = e.target.files[0];
    e.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;

        if (height === 585 && width === 1140) {
          setFile(file);
          //   setpreview(true);
        } else {
          // alert('Image width and height must be 1140x585 px');
          toast.error('Image width and height must be 1140x585 px', {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      };
    };
    reader.onerror = function (error) {};
  };
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const clearState = () => {
    setDescription('');
    setResult('');
    setChallenge('');
    setSolution('');
    setcatetory(false);
    setFile('');
    setUserInfo({
      file: '',
      title: '',
      client: '',
      location: '',
      catrgoryId: '',
      tags: '',
      youtube: '',
    });
    setUserInfo({
      file: '',
      title: '',

      client: '',
      location: '',
      catrgoryId: '',
      tags: '',
      youtube: '',
    });
    console.log('empty');
    return;
  };
  const cancleInformation = () => {
    // setcatetory(false);
    //  setFile('')
    //  setUserInfo({
    //   file: '',
    //   title: '',
    //   client: '',
    //   location: '',
    //   catrgoryId: '',
    //   tags: '',
    //   youtube: '',
    // });
    // setUserInfo({
    //   file: '',
    //   title: '',
    //   // description: '',
    //   // challenge: '',
    //   // solution: '',
    //   // result: '',
    //   client: '',
    //   location: '',
    //   catrgoryId: '',
    //   tags: '',
    //   youtube: '',
    // });
    clearState();
  };
  const saveInformation = () => {
    setIsLoadingMore(true);
    if (
      file !== '' &&
      userInfo.title !== '' &&
      userInfo.description !== '' &&
      userInfo.catrgoryId !== ''
    ) {
      const data = {
        image: file,
        title: userInfo.title,
        description: description,
        video: userInfo.youtube,
        clientName: userInfo.client,
        category: userInfo.catrgoryId,
        tags: userInfo.tags,
        result: result,
        challenge: challenge,
        solution: solution,
      };

      const formData = new FormData();

      Object.keys(data).forEach(function (key) {
        formData.append(key, data[key]);
      });
      console.log('formData', formData);
      GetCreateCaseStudies(formData).then((response) => {
        if (response.status === true) {
          setIsLoadingMore(false);
          toast.info(`${response.data.message}`, {
            className: 'style',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFile('');
          setUserInfo({
            file: '',
            title: '',
            client: '',
            location: '',
            catrgoryId: '',
            tags: '',
            youtube: '',
          });
          clearState();
        }
      });
    } else if (userInfo.youtube === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Youtube Link', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (file === '') {
      setIsLoadingMore(false);
      toast.error('Please provide image', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.title === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Title', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.description === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Description', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.catrgoryId === '') {
      setIsLoadingMore(false);
      toast.error('Please select Category', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoadingMore(false);
      toast.error('Error', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="cases">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <>
          <div className="relative md:ml-64" style={{ height: '105%' }}>
            <div className="flex flex-wrap">
              <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <h1 className="addArticle_heading">
                  All Fields are mandatory:
                </h1> */}

                    <div style={{ marginTop: '20px' }}>
                      <label className="addArticle_label">YouTube Link</label>
                      <br></br>
                      <input
                        type="text"
                        placeholder="YouTube Link"
                        name="youtube"
                        value={userInfo.youtube}
                        onChange={handleInput}
                        style={styles.inputBox}
                      />
                    </div>

                    <div
                      className="addArticle_label"
                      style={{ marginTop: '10px' }}
                    >
                      Please upload image in the dimension of 1140x585*
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      {file && (
                        <img
                          src={file && URL.createObjectURL(file)}
                          alt=""
                          style={{
                            width: 278,
                            height: 235,
                            marginBottom: '10px',
                            border: '2px solid gray',
                          }}
                        />
                      )}

                      <input
                        type="file"
                        name="file"
                        onChange={handleFileInput}
                        id="file"
                        style={{
                          backgroundColor: '#000',
                          height: 60,
                          width: 60,
                          position: 'absolute',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                      <label htmlFor="file">
                        <Button
                          variant="contained"
                          component="span"
                          htmlFor="file"
                          style={{ fontWeight: 'bold' }}
                        >
                          Upload
                          <FileUploadIcon />
                        </Button>{' '}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Title*</label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Title *"
                      name="title"
                      value={userInfo.title}
                      style={styles.inputBox}
                      onChange={handleInput}
                    />
                  </div>

                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Description*</label>
                    <br></br>
                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Description*"
                      name="description"
                      value={userInfo.description}
                      onChange={handleInput}
                      style={{ borderRadius: '4px' }}
                    ></textarea> */}
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        setDescription(editor.getData());
                      }}
                    />

                    {/* <input
                  type="text"
                  placeholder="Description*"
                  name="description"
                  value={userInfo.description}
                  onChange={handleInput}
                  style={styles.inputBox}
                /> */}
                  </div>

                  <div>
                    {/* <textarea
                  name="story"
                  rows="3"
                  cols="100"
                  id="textbox"
                  value={formData.story}
                  placeholder="Write the Article Here...     (Min 100 Words) "
                  onChange={onFormDataChange}
                  style={{ marginTop: 20, borderRadius: '5px' }}
                ></textarea> */}
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Challenge*</label>
                    <br></br>

                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Challenge*"
                      name="challenge"
                      value={userInfo.challenge}
                      onChange={handleInput}
                      style={{ borderRadius: '4px' }}
                    ></textarea> */}
                    <CKEditor
                      editor={ClassicEditor}
                      data={challenge}
                      onChange={(event, editor) => {
                        setChallenge(editor.getData());
                      }}
                    />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Solution*</label>
                    <br></br>

                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Solution*"
                      name="solution"
                      value={userInfo.solution}
                      onChange={handleInput}
                      style={{ borderRadius: '4px' }}
                    ></textarea> */}

                    <CKEditor
                      editor={ClassicEditor}
                      data={solution}
                      onChange={(event, editor) => {
                        setSolution(editor.getData());
                      }}
                    />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Result*</label>
                    <br></br>

                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Result*"
                      name="result"
                      value={userInfo.result}
                      onChange={handleInput}
                      style={{ borderRadius: '4px' }}
                    ></textarea> */}

                    <CKEditor
                      editor={ClassicEditor}
                      data={result}
                      onChange={(event, editor) => {
                        setResult(editor.getData());
                      }}
                    />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Name Of Client</label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Name of client"
                      name="client"
                      value={userInfo.client}
                      style={styles.inputBox}
                      onChange={handleInput}
                    />
                  </div>
                  {/* <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label">Location*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Location*"
                  name="location"
                  value={userInfo.location}
                  style={styles.inputBox}
                  onChange={handleInput}
                />
              </div> */}
                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Tags</label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Tags"
                      name="tags"
                      value={userInfo.tags}
                      style={styles.inputBox}
                      onChange={handleInput}
                    />
                  </div>
                  <div>
                    <>
                      <select
                        style={{
                          //   marginLeft: 20,
                          border: '2px solid #acb9ce',
                          borderRadius: 10,
                          marginBottom: '1.25rem',
                          marginTop: '0.75rem',
                          width: '160px',
                          height: '47px',
                        }}
                        className="mb-5 mt-3"
                        value={userInfo.catrgoryId}
                        onChange={onCategorySelected}
                      >
                        <option value="">Category</option>
                        <option value="pharmaceutical">Pharmaceutical</option>
                        <option value="automobile">Automobile</option>
                        <option value="outsourcing">Outsourcing</option>
                        <option value="bFSI">BFSI</option>
                        <option value="executive@">Executive</option>

                        <option value="IT Services">IT Services </option>
                        <option value="Private Sector Bank">
                          Private Sector Bank{' '}
                        </option>
                        <option value="Financial Services">
                          Financial Services
                        </option>
                        <option value="Bank">Bank</option>
                        <option value="Publishing">Publishing</option>
                        <option value="Telecom Services">
                          Telecom Services
                        </option>
                        <option value="media">Media</option>
                        <option value="Automotive">Automotive</option>
                        <option value="IT/ITES">IT/ITES</option>
                        <option value="Petroleum and Refinery">
                          Petroleum and Refinery{' '}
                        </option>
                      </select>
                    </>
                  </div>

                  <div className="col-lg-12">
                    {/* <button
                  onClick={saveInformation}
                  className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  Save
                </button> */}
                    <Button
                      variant="contained"
                      onClick={saveInformation}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      style={{ backgroundColor: 'green' }}
                    >
                      Save
                    </Button>
                    {/* <button
                   onClick={cancleInformation}
                  className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  type="button"
                  style={{ backgroundColor: 'red', marginLeft: '10px' }}
                >
                  {'Draft Article'}
                  Cancel
                </button> */}
                    <Button
                      variant="contained"
                      onClick={cancleInformation}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      style={{
                        backgroundColor: 'rgb(220,20,60)',
                        marginLeft: '10px',
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <ScaleLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={20}
            />
          </div>
        </>
      </div>
        <ToastContainer />
    </div>
  );
}
const styles = {
  inputBox: {
    width: '500px',
    height: '30px',
    marginTop: 5,
    borderRadius: '5px',
  },
};

export default AddCaseStudies;
