import React,{useState,useEffect} from 'react';
import { useParams ,useNavigate} from "react-router-dom"
import axios from 'axios';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {GetUpdateCareer,GetEditCareer} from '../../handlers/career.handler'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../assets/style.css'


const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function EditCareer() {
    const navigate = useNavigate();
    const params = useParams()
    const [file, setFile] = useState('');
  
    
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [userInfo, setUserInfo] = useState({
        job_title:'',
      
        category: '',
        created_By:'',
        
      });
      const [description,setDescription] = useState('');
      const cancleInformation = () =>{
       navigate('/allcareer')
      }
      

      const clearState = () => {
        setDescription('')
        setUserInfo({job_title: "",
       // job_description: "",
        category: "",
        created_By: "",
        job_Location: "",
         
        });
      
    
      };


      const saveEditInformation = async (event) => {
        console.log("userinfo",userInfo);
          console.log("file",file)
        setIsLoadingMore(true)
        if(userInfo.job_title !== "" && description !== "" && userInfo.category !== ""  && userInfo.created_By !== ""){
        const data ={
        _id:params.id, 
          job_title:userInfo.job_title,
          job_description:description,
          category: userInfo.category,
          created_By: userInfo.created_By,
        }
        
    GetUpdateCareer(data).then((response) => {
          if (response.status === true) {
            console.log("case",response.data)
            // alert(`${response.data.message}`);
            setIsLoadingMore(false)
            toast.success('Case Studies edit succecfully!', {
              className:"style",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              navigate('/allcareer');
           
          }
        });
      }
      else if(userInfo.job_title === ''){
        setIsLoadingMore(false)
        toast.error('Please provide Job Title', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
     else if(userInfo.job_description  === ''){
      setIsLoadingMore(false)
        toast.error('Please provide Job description', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else if(userInfo.category === ''){
        setIsLoadingMore(false)
        toast.error('Please provide Category', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else if(userInfo.created_By === ''){
        setIsLoadingMore(false)
        toast.error('Please select Created By', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else{
        setIsLoadingMore(false)
        toast.error('Error', {
          className:"style",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      };
      
      const onCategorySelected = (e) => {
        setUserInfo({ ...userInfo, category: e.target.value });
      };
      const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserInfo({ ...userInfo, [name]: value });
        
      };
      useEffect(() => {
        getCaseStudiesId()
      }, []);
  const getCaseStudiesId = async()=>{
    GetEditCareer(params.id).
  //  await axios.post(`http://52.250.20.141/silverleaf-backend/api/career/editJob/${params.id}`).
    then ((res)=>{
        console.log("check")
      
        console.log("id",res.data.data.job_title)
       // setUserInfo(res.data.data)
      setDescription (res.data.data.job_description)
        setUserInfo({
          job_title:res.data.data.job_title,
           job_description: res.data.data.job_description,
           category: res.data.data.category,
          created_By:res.data.data.created_By,
          
        })
      })

   
    }
  return (
    <div className='cases'>
    <Sidebar />
    <div className="newContainer">
    <Navbar />
    <>
   

    <div className="relative md:ml-64" >
      <div className="flex flex-wrap">
        <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
          <div className="row">
            <div className="col-lg-12">
              {/* <h1 className="addArticle_heading">
                All Fields are mandatory:
              </h1> */}
              
              <div style={{ marginTop: '20px' }}>
            <label className="addArticle_label">Job Title*</label>
              <br></br>
              <input
                type="text"
                placeholder="job_title"
                name="job_title"
                value={userInfo.job_title}
                onChange={handleInput}
                style={styles.inputBox}
              />
            </div>
              
              
            </div>
          </div>
          <div className="row">
            <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label">Job Description*</label>
              <br></br>
              {/* <input
                type="text"
                placeholder="job_description *"
                name="job_description"
                value={userInfo.job_description}
                style={styles.inputBox}
                onChange={handleInput}
              /> */}
              
                <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={ ( event, editor ) => {
                        setDescription(editor.getData())
                        
                    } }
                      
                    />
              
            </div>
            
            <div>
              <>
              <select
                  style={{
                  //   marginLeft: 20,
                    border: '2px solid #acb9ce',
                    borderRadius: 10,
                    marginBottom:"1.25rem",
                    marginTop:"0.75rem",
                    width:'160px',
                    height:'47px'
                  }}
                  className="mb-5 mt-3"
                  name="category"
                  value={userInfo.category}
                  onChange={handleInput}
                >
                 <option value="">Category</option>
                    <option value="admin">Admin</option>
                    <option value="hr">Hr</option>
                    <option value="software Engineer">Software Engineer</option>
                    <option value="operations">Operations</option>
                    <option value="sales">Sales</option>

                 
                </select>
              </>
            </div>
            <div style={{ marginTop: '20px' }}>
              <label className="addArticle_label">Created By*</label>
              <br></br>
              <input
                type="text"
                placeholder="created_By*"
                name="created_By"
                value={userInfo.created_By}
                style={styles.inputBox}
                onChange={handleInput}
              />
            </div>

            <div className="col-lg-12">
              <button
                onClick={saveEditInformation}
                className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
              >
                Save
              </button>
              <button
                onClick={cancleInformation}
                className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                style={{ backgroundColor: 'red', marginLeft: '10px' }}
              >
                {/* {'Draft Article'} */}
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <ScaleLoader
        color="#23A5E0"
        loading={isLoadingMore}
        css={override}
        size={20}
      />
    </div>
    
  </>


    </div>
      <ToastContainer />
  </div>
  )
}
const styles = {
    inputBox: {
      width: '500px',
      height: '30px',
      marginTop: 5,
      borderRadius: '5px',
    },
  };

export default EditCareer