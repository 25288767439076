import React,{useEffect, useState} from 'react'
import { useParams ,useNavigate} from "react-router-dom"
import {GetAppliedUser} from '../../handlers/career.handler'
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

function ApplyPositionList() {
     const navigate = useNavigate();
    const params = useParams()
    const [data, setData] = useState([]);
    
    useEffect(() => {
    getAppliedUser()
  }, []);
  const getAppliedUser = () => {
    // setIsLoadingMore(true);
    GetAppliedUser().then((response) => {
      if (response.message === 'list') {
        setData(response.data.data);
        console.log("response.data.data",response)
        // setIsLoadingMore(false);
      }
    });
  }
  function isUser (item){
      return item.category === params.role
  }
//   appliedPeople = data.filter((item) => return {item.category === params.id})
const appliedPeople =  data.filter(isUser) &&  data.filter(isUser)
if(appliedPeople){
    var datas = appliedPeople[0]
}
 const backButton = ()=>{
      navigate("/allcareer")
    }

  return (
    <div>
       <div className="list">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {/* {confirmationModal.show && (
          <ConfirmationModal
            message={confirmationModal.message}
            title={confirmationModal.title}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )} */}
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <h1 style={{fontSize:"37px",fontWeight:"141px",marginLeft:"432px"}}> Job Title : <spam style={{textTransform:"uppercase"}}>{params.role}</spam></h1>
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                Name
               
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
             Phone Number
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  email
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                     'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      
                  }
                >
                  Resume
                </th>
               
                </tr>

            </thead>
            <tbody>
              
              {
                datas && datas.items && datas.items.map((row)=>(
                  
                  <tr>
                  
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"138px"}}>
                     {row.name}
                  </td>
                  {console.log("row.name",row.name)}
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"400px"}}>
                   {row.mobile}
                    
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"400px"}}>
                   {row.email}
                    
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"400px"}}>
              
                   Resume
                   <a href={row.resume} download><DownloadIcon  color="primary"/></a>
                  </td>
                  
                  

                  </tr>
                ))
              }
             
            </tbody>
            </table>
                <Button
onClick={backButton}
                variant="contained" 
                style={{marginLeft:"1010px", marginTop:"10px"}}
              >
              <ArrowBackIcon/> Back
              </Button>
            </div>

      </div>
  
      <div >
        {/* <ScaleLoader
          color="#23A5E0"
          loading={isLoadingMore}
          css={override}
          size={20}
        /> */}
      </div>
      
    </div>
  );
    </div>
  )
}

export default ApplyPositionList