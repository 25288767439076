import React, { useState, useEffect } from 'react';
// import './list.css';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableCell } from '@mui/material';
import { TableBody } from '@mui/material';
import { Table } from '@mui/material';
import { TableContainer } from '@mui/material';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import EditIcon from '@mui/icons-material/Edit';
import Single from '../single/AddCaseStudies';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { GetAllCareer, GetDeleteCareer } from '../../handlers/career.handler';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ReactHtmlParser from 'html-react-parser';
// import dateFormat from 'dateformat'

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function AllCareer() {
  const [data, setData] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [caseId, setCaseId] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const openUpdate = '';

  useEffect(() => {
    getCaseStudy();
  }, []);
  const getCaseStudy = () => {
    setIsLoadingMore(true);
    GetAllCareer().then((response) => {
      if (response.message === 'success') {
        setData(response.data.data);
        console.log('response.data.data', response.data.data);
        setIsLoadingMore(false);
      } else alert('Something went wrong');
    });
  };
  const handleDeleteClick = (id) => {
    setCaseId(id);
    showModalForPublish();
  };
  const showModalForPublish = () => {
    let title = 'Delete Case Study ?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const onSuccess = () => {
    setIsLoadingMore(true);
    GetDeleteCareer(caseId).then((response) => {
      if (response.status === true) {
        console.log('donecase');
        setIsLoadingMore(false);
        toast.success('Case Studies deleted succecfully!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setConfirmationModal({
          message: '',
          show: false,
          title: '',
        });
        getCaseStudy();
      }
    });
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {confirmationModal.show && (
          <ConfirmationModal
            message={confirmationModal.message}
            title={confirmationModal.title}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Job Title
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Job Description
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Category
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Created By
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Job Location
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Date
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((row) => (
                  <tr>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '138px' }}
                    >
                      <Link to={`/applypositionlist/${row.category}`}>
                        {row.job_title}
                      </Link>
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '400px' }}
                    >
                      {/* {row.job_description} */}
                      <Link to={`/applypositionlist/${row.category}`}>
                        {' '}
                        {row &&
                        row.job_description &&
                        row.job_description.length < 30
                          ? ReactHtmlParser(row.job_description)
                          : ''}
                        {row &&
                        row.job_description &&
                        row.job_description.length > 65
                          ? ReactHtmlParser(
                              row.job_description.slice(0, 65) + '...'
                            )
                          : ''}
                      </Link>
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '400px' }}
                    >
                      <Link to={`/applypositionlist/${row.category}`}>
                        {' '}
                        {row.category}
                      </Link>
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '400px' }}
                    >
                      <Link to={`/applypositionlist/${row.category}`}>
                        {row.created_By}
                      </Link>
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '138px' }}
                    >
                      <Link to={`/applypositionlist/${row.category}`}>
                        {row && row.job_Location === ''
                          ? 'N/A'
                          : row.job_Location}
                      </Link>
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '138px' }}
                    >
                      <Link to={`/applypositionlist/${row.category}`}>
                        {new Date(row.job_date).toLocaleDateString('en-us', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </Link>
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '133px' }}
                    >
                      <Link to={`/editcareer/${row._id}`}>
                        <EditIcon
                          style={{ cursor: 'pointer', marginRight: '22px' }}
                        />
                      </Link>
                      <DeleteIcon
                        onClick={() => {
                          handleDeleteClick(row._id);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <ScaleLoader
          color="#23A5E0"
          loading={isLoadingMore}
          css={override}
          size={20}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default AllCareer;
