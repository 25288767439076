import React, { useEffect, useState } from "react";
import styles from "./Gallery.module.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import AddGalleryModal from "./AddGalleryModal/AddGalleryModal";
import {
  GetAllGallery,
  GetDeleteGallery,
} from "../../handlers/gallery.handler";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";

import "../../assets/style.css";
import LoadingSpinner from "../../components/icons/LoadingSpinner";

const dummyData = [
  {
    title: "Event Title",
    src: "https://t3.ftcdn.net/jpg/06/48/79/10/360_F_648791013_cQK30SdyiLrVQ96Bqn2MOkz4JmvgttGr.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati eaque rerum! Provident similique tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    title: "Event Title",
    src: "https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    title: "Event Title",
    src: "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    title: "Event Title",
    src: "https://t3.ftcdn.net/jpg/06/48/79/10/360_F_648791013_cQK30SdyiLrVQ96Bqn2MOkz4JmvgttGr.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    title: "Event Title",
    src: "https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    title: "Event Title",
    src: "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
];

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const Gallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [galleryItemId, setGalleryItemId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteGalleryLoading, setDeleteGalleryLoading] = useState(false);

  useEffect(() => {
    fetchGallery();
  }, []);

  const fetchGallery = () => {
    setIsLoading(true);
    GetAllGallery().then((response) => {
      if (response.message === "success") {
        setData(response.data.data.galleryList);
        setIsLoading(false);
      } else alert("Something went wrong");
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setGalleryItemId("");
  };

  const handleEdit = (id) => {
    setShowModal(true);
    const filteredData = data.filter((item) => item._id === id)?.pop();
    setGalleryItemId(filteredData);
  };

  const handleDeleteGallery = () => {
    setDeleteGalleryLoading(true);
    GetDeleteGallery(showDeleteModal).then((res) => {
      console.log("response", res);
      if (res.status) {
        const updatedData = data.filter((item) => item._id !== showDeleteModal);
        setData(updatedData);
      }
      setDeleteGalleryLoading(false);
      setShowDeleteModal("");
    });
  };

  return (
    <div className="new">
      <div className="wrapper">
        <Sidebar />
        <section className={styles.section}>
          <ScaleLoader
            color="#23A5E0"
            loading={isLoading}
            css={override}
            size={20}
          />

          {!isLoading && (
            <div className={styles.innerContainer}>
              <Button
                variant="contained"
                component="span"
                htmlFor="file"
                style={{ fontWeight: "bold" }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add
                <Add />
              </Button>

              <div className={styles.listContainer}>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    const isTypeImage =
                      item.type === "image" ||
                      item.image.includes(".jpg") ||
                      item.image.includes(".png") ||
                      item.image.includes(".jpeg");

                    return (
                      <div key={index} className={styles.item}>
                        <div className={styles.itemImgContainer}>
                          <button
                            className={styles.editBtn}
                            onClick={() => {
                              handleEdit(item._id);
                            }}
                          >
                            <Edit />
                          </button>

                          <button
                            className={`${styles.editBtn} ${styles.deleteBtn}`}
                            onClick={() => {
                              setShowDeleteModal(item._id);
                            }}
                          >
                            <Delete />
                          </button>

                          {isTypeImage ? (
                            <img src={item.image} alt={item.title} />
                          ) : (
                            <video src={item.image} controls />
                          )}
                        </div>
                        <div className={styles.itemInfo}>
                          <h4>{item.title}</h4>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </section>
      </div>

      {showDeleteModal && (
        <>
          <div className={styles.deleteModal}>
            <p>Are you sure you want to delete this Gallery Item!!</p>
            <Button
              variant="contained"
              onClick={() => {
                setShowDeleteModal("");
              }}
              disabled={deleteGalleryLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={styles.confirmDeleteBtn}
              onClick={handleDeleteGallery}
              disabled={deleteGalleryLoading}
            >
              {deleteGalleryLoading ? <LoadingSpinner /> : "Delete"}
            </Button>
          </div>
          <div
            className={styles.overlay}
            onClick={() => {
              setShowDeleteModal("");
            }}
          />
        </>
      )}

      <AddGalleryModal
        show={showModal}
        onClose={closeModal}
        updateGallery={galleryItemId}
        onSubmit={fetchGallery}
      />
    </div>
  );
};

export default Gallery;
