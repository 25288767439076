import React from 'react';
import '../new/Banners.css';
import Sidebar from '../../components/sidebar/Sidebar';
import 'react-toastify/dist/ReactToastify.css';
import { GetALLAward, GetDeleteAwards } from '../../handlers/awards.handler';
import { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { ToastContainer, toast } from 'react-toastify';
const Awards = () => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [docs, setDocs] = useState([]);
  const [awardId, setAwardId] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const getAwardData = () => {
    setIsLoadingMore(true);
    const data = {
      page: 1,
      limit: 15,
    };
    GetALLAward(data).then((response) => {
      setDocs(response.data.data.awardsList);
      if (response.status === true) {
        setIsLoadingMore(false);
      }
    });
  };
  useEffect(() => {
    getAwardData();
  }, []);
  const showModalForPublish = () => {
    let title = 'Delete Award ?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const handleDeleteClick = (id) => {
    console.log('id', id);
    setAwardId(id);
    showModalForPublish();
  };
  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    setIsLoadingMore(true);
    GetDeleteAwards(awardId).then((response) => {
      if (response.status === true) {
        setIsLoadingMore(false);
        toast.success('Award deleted succecfully!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setConfirmationModal({
          message: '',
          show: false,
          title: '',
        });
        getAwardData();
      }
    });
  };
  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {confirmationModal.show && (
            <ConfirmationModal
              message={confirmationModal.message}
              title={confirmationModal.title}
              onClose={onClose}
              onSuccess={onSuccess}
            />
          )}
          <div className="topnew" style={{ marginTop: '3px' }}>
            <h1 style={{ color: 'black' }}>Award</h1>
          </div>
          <div className="block w-full overflow-x-auto">
            <table className="table-fixed">
              <thead>
                <tr>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    }
                  >
                    Image
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    }
                  >
                    Title
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    }
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {docs?.map((item) => (
                  // const [id]=item;
                  <tr>
                    <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                      <div
                        style={{
                          background: `url('${item.image}?${item._id}') 0% 0% / cover no-repeat`,
                          width: '100px',
                          height: '53px',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}
                      ></div>
                    </th>

                    <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <p>{item.title}</p>
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '133px' }}
                    >
                      <Link to={`/updateAward/${item._id}`}>
                        <EditIcon
                          style={{ cursor: 'pointer', marginRight: '22px' }}
                        />
                      </Link>
                      <DeleteIcon
                        onClick={() => {
                          handleDeleteClick(item._id);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Awards;
