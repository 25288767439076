import React from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import './home.css';
import welcome from '../../assets/welcome.jpg';
function Home() {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        {/* <Navbar /> */}

        <img
          src={welcome}
          alt="welcome"
          style={{ width: '100vw', height: '100vh' }}
        />
      </div>
    </div>
  );
}

export default Home;
