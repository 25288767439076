import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import {
  Button,
  FormGroup,
  FormControl,
 FormLabel,
  InputGroup,
} from "react-bootstrap";
import eye from "./eye.png";

import Axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import {adminLogin} from "../../handlers/login.handler"

const Login = () => {

   const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
    revealPassword: "",
    disableSubmit: true,
  });
  const onFormSubmit = () => {
    if (state.email !== "" && state.password !== "") {
      login();
    } else {
      //   alert("Check your Credentials");
      toast.error("Username or Password can't be empty");
    }
  };
  const isFormValid = () => state.email.length > 0 && state.password.length > 0;

  const updateInputField = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };
useEffect(() => {
    // change background color with a random color
    const color = Math.floor(Math.random()*16777215).toString(16);
    document.body.style.background = color;
  });
  // redirect if user data is present.
  // React.useEffect(() => {
  //   console.log("debug akshay", props.userData);

  //   if (props.userData) {
  //     history.push("/dashboard");
  //   } else {
  //     history.push("/");
  //   }
  // }, [props.userData]);

  //Login
const data = {
  email: state.email,
  password: state.password,
}
  const login = () => {
    // Axios.post(
    //   "http://52.250.20.141/silverleaf-backend/api/admin/login",
    //   {
    //     email: state.email,
    //     password: state.password,
    //   },
    //   {
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //   }
    // )
    adminLogin(data)
      .then((res) => {
        console.log(res.data.data.status)
        if (res.data.data.responseCode == 400) {
          // props.loginUser(res.data.result.user, res.data.result.authToken);
          console.log("check",res.data.data.responseCode);
         
           toast.error("Invalid email or password", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
         } else if(res.data.data.status === 200){
            toast("Login Sucessfully");
            navigate("/banners");
        }
        
      })
      .catch((error) => {
        alert("Check your credentials");
        let erro = error.response.data.status;
        console.log("erro",erro);
        toast.error(erro);
      });
  };

  return (
    // <div style={{backgroundColor:'red'}}>
    // <div>
    //     <div style={{alignContent:"center"}}>Welcome</div>
    <div className="Login">
      <div>
         <img
                    className="login__logo"
                    src='https://silverleafsolutions.com/site/wp-content/uploads/2018/09/logo.png' 
                />
      </div>
       <div className='login__container'>
                <h2>Login</h2>
      <form style={{backgroundColor:"white",gap:"17px"}}>
        <FormGroup
          controlId="email"
          bsSize="large"
          style={{width:"100% ",backgroundColor:"white"}}
        >
          <FormLabel
            style={{
              display: "inlineBlock",
              marginBottom: "5px",
              fontWeight: "700",
              maxWidth: "100%",
              backgroundColor:"white"
            }}
          >
            Username
          </FormLabel>
          <FormControl
            autoFocus
            type="text"
            name="email"
            value={state.email}
            onChange={updateInputField}
            required={true}
            style={{ height: "46px", fontWeight: "499", fontSize: "18px", width:"100%", borderRadius:"4px" }}
          />
        </FormGroup>
        <FormGroup
          controlId="password"
          bsSize="large"
          style={{width:"100%",backgroundColor:"white"}}
        >
          <FormLabel
            style={{
              display: "inlineBlock",
              marginBottom: "5px",
              fontWeight: "700",
              maxWidth: "100%",
              backgroundColor:"white"
            }}
          >
            Password
          </FormLabel>
          <InputGroup style={{
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
           alignItems: "stretch",
    width:"100%",
     borderRadius:"4px" 
          }}>
            <FormControl
              type={state.revealPassword ? "text" : "password"}
              value={state.password}
              name="password"
              onChange={updateInputField}
              required={true}
              style={{ height: "46px", width:"85%" }}
            />
            <InputGroup
              style={{
                padding: "4px",
                borderLeft: "0",
                verticalAlign: "middle",
                border: "1px solid #ccc",
                textAlign: "center",
                backgroundColor:"white"
              }}
              onClick={() =>
                updateInputField({
                  target: {
                    name: "revealPassword",
                    value: !state.revealPassword,
                  },
                })
              }
            >
              <img src={eye} alt="eye" style={{ height: "36px" ,backgroundColor:"white"}} />
            </InputGroup>
          </InputGroup>
        </FormGroup>
        <Button
          block
          bsSize="large"
          style={{
            backgroundColor: "rgb(29, 117, 189)",
            color: "rgb(255, 255,255)",
            marginBottom: "11px",
            width: "100%",
            cursor: "pointer",
             borderRadius:"4px" 
          }}
          onClick={() => {
            onFormSubmit();
          }}
        >
          Login
        </Button>
        <br />
        {/* <a
          className="btn btn-lg btn-default btn-block"
          style={{
            backgroundColor: "rgb(29, 117, 189)",
            color: "rgb(255, 255,255)",
            marginBottom: "15px",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => history.push("forgot_password")}
        >
          Forgot Password
        </a> */}
      </form>
      </div>
      <ToastContainer />
    </div>

    // </div>
  );
};



export default Login;
