import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import '../../assets/style.css';
import { GetCreateAward } from '../../handlers/awards.handler';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const AddSocialMediaLinkAward = (props) => {
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const [title, setTitle] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleInput = (e) => {
    setTitle(e.target.value);
  };

  const handleFileInput = (e) => {
    let file = e.target.files[0];
    e.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        setFile(file);
      };
    };
  };
  const saveInformation = () => {
    setIsLoadingMore(true);
    const data = {
      title: title,
      image: file,
    };

    const formData = new FormData();

    Object.keys(data).forEach(function (key) {
      formData.append(key, data[key]);
    });
    GetCreateAward(formData).then((response) => {
      if (response.status === true) {
        setIsLoadingMore(false);
        alert(`${response.data.message}`);
        navigate('/awards');
        clearState();
      }
    });
  };

  const clearState = () => {
    setTitle('');
  };

  const cancleInformation = () => {
    clearState();
  };
  return (
    <div className="cases">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <>
          <div className="relative md:ml-64">
            <div className="flex flex-wrap">
              <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div style={{ marginTop: '20px' }}>
                      <div className="blog-form">
                        <form action="">
                          <label className="addArticle_label">
                            Add Banner*
                          </label>
                          <input
                            type="text"
                            placeholder="Title*"
                            name="title"
                            value={title}
                            onChange={handleInput}
                          />{' '}
                          <br />
                          <div className="addBlog-img">
                            <img
                              src={file && URL.createObjectURL(file)}
                              alt="File"
                              width="250px"
                              height="200px"
                            />
                          </div>
                          <br />
                          <input
                            type="file"
                            name="file"
                            onChange={handleFileInput}
                          />
                        </form>
                      </div>
                    </div>

                    <div style={{ paddingTop: 30, marginLeft: 0 }}>
                      <Button
                        type="button"
                        onClick={saveInformation}
                        variant="contained"
                        className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        //   style={{ width: "100px", backgroundColor: "rgb(220,20,60)" }}
                      >
                        Save
                      </Button>
                      <button
                        onClick={cancleInformation}
                        className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="button"
                        style={{
                          backgroundColor: 'red',
                          marginLeft: '10px',
                          paddingBottom: '11px',
                        }}
                      >
                        {/* {'Draft Article'} */}
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <label>Key Words*</label><br/> */}

                  <div>
                    <></>
                  </div>
                </div>
              </div>
            </div>
            <ScaleLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={20}
            />
          </div>
        </>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddSocialMediaLinkAward;
