// export const BASE_PATH = `http://52.250.20.141/silverleaf-backend/api/`;
// export const BASE_PATH = `http://54.197.247.210/backend/`;
// export const BASE_PATH = `https://api.silverleaf.nssplindia.com/api/`; //staging
export const BASE_PATH = `https://api.silverleafsolutions.com/api/`; //production

export const API_ROUTES = {
  BANNERS: {
    GET_ALL_BANNERS: "banner/getBanners",
    CREATE_BANNER: "banner/createBanner",
    DELETE_BANNER: "banner/deleteBanner",
    UPDATE_BANNER: "banner/updateBanner",
  },
  AWARDS: {
    GET_ALL_AWARDS: "awards/getAwards",
    CREATE_AWARDS: "awards/addAwards",
    DELETE_AWARD: "awards/deleteAward",
    UPDATE_AWARD: "awards/updateAward",
    EDIT_AWARD: "awards/getAwardById",
  },
  CASE_STUDY: {
    CREATE_CASE_STUDY: "caseStudy/addCaseStudy",
    GET_ALL_CASE_STUDY: "caseStudy/getCaseStudy",
    DELETE_CASE_STUDY: "caseStudy/deleteCaseStudy",
    UPDATE_CASE_STUDY: "caseStudy/updateCaseStudy",
    EDIT_CASE_STUDY: "caseStudy/getCaseStudyById",
  },
  Blogs: {
    CREATE_Blogs: "blogs/addBlog",
    GET_ALL_Blogs: "blogs/getAllBlogs",
    DELETE_Blogs: "blogs/deleteBlog",
    UPDATE_Blogs: "blogs/updateBlog",
    EDIT_Blogs: "blogs/getBlogById",
  },

  Partner_With_Us: {
    GET_ALL_Partner: "partnerWithUs/getAllPartners",
    DELETE_Partner: "partnerWithUs/deletePartner",
    EDIT_Partner: "partnerWithUs/getPartnerById",
  },
  Career: {
    ADD_Career: "career/addJobDetails",
    EDIT_Career: "career/editJob",
    Get_Career: "career/getJobs",
    Delete_Career: "career/deleteJob",
    Update_Career: "career/updateJob",
    LIST_Appplied_User: "career/listOfJobAppliedUser",
  },
  Gallery: {
    Get_All_Gallery: "gallery/getAllGalleryItems",
    Get_Single_Gallery: "gallery/getGalleryItemById",
    ADD_Gallery: "gallery/addGalleryItem",
    EDIT_Gallery: "gallery/updateGalleryItem",
    Delete_Gallery: "gallery/deleteGalleryItem",
  },
  Login: {
    login: "admin/login",
  },
};
