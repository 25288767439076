import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { GetUpdateBlogs, GetEditBlog } from '../../handlers/blogs.handlers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../assets/style.css';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function UpdateBlogs() {
  const navigate = useNavigate();
  const params = useParams();
  const [file, setFile] = useState('');
  const [num, setNum] = useState(0);
  const [imagefile, setimageFile] = useState('');
  const [description, setDescription] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [userInfo, setUserInfo] = useState({
    file: '',
    // description: "",
    title: '',
    category: '',
    video: '',
    createdBy: '',
  });
  const cancleInformation = () => {
    navigate('/allblogs');
  };

  const saveEditInformation = async (event) => {
    setNum(num + 1);
    console.log('file', file);
    setIsLoadingMore(true);
    if (
      (file !== '' || imagefile !== '') &&
      userInfo.title !== '' &&
      userInfo.description !== '' &&
      userInfo.category !== ''
    ) {
      const data = {
        _id: params.id,
        image: file ? file : imagefile,
        title: userInfo.title,
        description: description,
        video: userInfo.video,
        category: userInfo.category,
        createdBy: userInfo.createdBy,
      };
      console.log('userInfofile', data.image);
      const formData = new FormData();

      Object.keys(data).forEach(function (key) {
        formData.append(key, data[key]);
      });
      console.log('formData', formData);
      GetUpdateBlogs(formData).then((response) => {
        if (response.status === true) {
          console.log('case', response.data);
          // alert(`${response.data.message}`);
          setIsLoadingMore(false);
          toast.success('Case Studies edit succecfully!', {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: 'style',
          });
          navigate('/allblogs');
        }
      });
    } else if (file === '') {
      setIsLoadingMore(false);
      toast.error('Please provide image', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.title === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Title', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.description === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Description', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.category === '') {
      setIsLoadingMore(false);
      toast.error('Please select Category', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoadingMore(false);
      toast.error('Error', {
        className: 'style',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  console.log('title', userInfo.title);
  const onCategorySelected = (e) => {
    setUserInfo({ ...userInfo, category: e.target.value });
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleFileInput = (e) => {
    // const name = e.target.name;
    // const value = e.target.value;
    // setUserInfo({ ...userInfo, [name]: value });
    let file = e.target.files[0];
    e.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;

        if (height === 585 && width === 1140) {
          setFile(file);
          //   setpreview(true);
        } else {
          alert('Image width and height must be 1140x585 px');
          //   toast.error('Image width and height must be 1140x585 px', {
          //     position: "top-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });
          return false;
        }
      };
    };
    reader.onerror = function (error) {};
  };
  useEffect(() => {
    getCaseStudiesId();
  }, []);
  const getCaseStudiesId = async () => {
    GetEditBlog(params.id)
      // await axios
      //   .get(
      //     `http://52.250.20.141/silverleaf-backend/api/blogs/getBlogById/${params.id}`
      //   )
      .then((res) => {
        console.log('check');
        console.log('id', res.data.data[0]);
        // setUserInfo(res.data.data[0])
        // setimageFile(res.data.data[0].image)
        console.log('id', res.data.data);
        setDescription(res.data.data.description);
        setUserInfo(res.data.data);
        setimageFile(res.data.data.image);
      });
  };
  return (
    <div className="cases">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <>
          <div className="relative md:ml-64">
            <div className="flex flex-wrap">
              <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div style={{ marginTop: '20px' }}>
                      <label className="addArticle_label">Video Link</label>
                      <br></br>
                      <input
                        type="text"
                        placeholder="Video Link"
                        name="video"
                        value={
                          userInfo.video === 'undefined' ? '' : userInfo.video
                        }
                        onChange={handleInput}
                        style={styles.inputBox}
                      />
                    </div>

                    <div
                      className="addArticle_label"
                      style={{ marginTop: '10px' }}
                    >
                      Please upload image in the dimension of 1140x585*
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      {console.log('get', imagefile)}
                      {/* {console.log("getfile",file && URL.createObjectURL(file))} */}

                      {imagefile && (
                        <img
                          src={file ? URL.createObjectURL(file) : imagefile}
                          alt=""
                          style={{
                            width: 278,
                            height: 235,
                            marginBottom: '10px',
                            border: '2px solid gray',
                          }}
                        />
                      )}

                      <input
                        type="file"
                        name="file"
                        onChange={handleFileInput}
                        id="file"
                        style={{
                          backgroundColor: '#000',
                          height: 60,
                          width: 60,
                          position: 'absolute',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                      <label htmlFor="file">
                        <Button
                          variant="contained"
                          component="span"
                          htmlFor="file"
                          style={{ fontWeight: 'bold' }}
                        >
                          Upload
                          <FileUploadIcon />
                        </Button>{' '}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Title*</label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Title *"
                      name="title"
                      value={userInfo.title}
                      style={styles.inputBox}
                      onChange={handleInput}
                    />
                  </div>
                  {/* <label>Key Words*</label><br/> */}
                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Description*</label>
                    <br></br>
                    {/* <textarea
                      rows="3"
                      cols="100"
                      type="text"
                      placeholder="Description*"
                      name="description"
                      value={userInfo.description}
                      onChange={handleInput}
                      style={{ borderRadius: "4px" }}
                    ></textarea> */}

                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        setDescription(editor.getData());
                      }}
                    />
                  </div>

                  <div style={{ marginTop: '20px' }}>
                    <label className="addArticle_label">Created By</label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Created By"
                      name="createdBy"
                      // value={userInfo.tags}
                      value={
                        userInfo.tags === 'undefined' ? '' : userInfo.createdBy
                      }
                      style={styles.inputBox}
                      onChange={handleInput}
                    />
                  </div>
                  <div>
                    <>
                      <select
                        style={{
                          //   marginLeft: 20,
                          border: '2px solid #acb9ce',
                          borderRadius: 10,
                          marginBottom: '1.25rem',
                          marginTop: '0.75rem',
                          width: '160px',
                          height: '47px',
                        }}
                        className="mb-5 mt-3"
                        value={userInfo.category}
                        onChange={onCategorySelected}
                      >
                        <option value="">Category</option>
                        <option value="pharmaceutical">Pharmaceutical</option>
                        <option value="automobile">Automobile</option>
                        <option value="outsourcing">Outsourcing</option>
                        <option value="bFSI">BFSI</option>
                        <option value="executive">Executive</option>

                        {/* {docs.map((author) => (
                    <>
                      <option
                        selectedValue={author && author._id}
                        value={author._id}
                      >
                        {author.name}
                      </option>
                    </>
                  ))} */}
                      </select>
                    </>
                  </div>

                  <div className="col-lg-12">
                    <button
                      onClick={saveEditInformation}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                    >
                      Save
                    </button>
                    <button
                      onClick={cancleInformation}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      type="button"
                      style={{ backgroundColor: 'red', marginLeft: '10px' }}
                    >
                      {/* {'Draft Article'} */}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ScaleLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={20}
            />
          </div>
        </>
      </div>
        <ToastContainer />
    </div>
  );
}
const styles = {
  inputBox: {
    width: '500px',
    height: '30px',
    marginTop: 5,
    borderRadius: '5px',
  },
};

export default UpdateBlogs;
