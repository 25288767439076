import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
function EditableRow({
  editImage,
  editFormData,
  handleEditFormChanges,
  saveEditInformation,
  setpreview,
  handleEditInput,
}) {
  const [newImage, setNewImage] = useState();
  // useEffect(()=>{},[setpreview])

  return (
    <div>
      <div className="bottomnew">
        <div className="leftnew">
          <img
            src={
              editImage && editImage.image
                ? editImage.image
                : URL.createObjectURL(editImage)
            }
            alt=""
          />
      
          <form>
            {/* <label htmlFor="files" > Image: <DriveFolderUploadIcon className='icon' onClick={() => setpreview(false)}/></label> */}
            {/* <input type="file" name='image' id="files" onChange={handleEditFormChanges}  onClick={() => setpreview(false)}  /> */}

            <input
              type="file"
              name="image"
              onChange={handleEditFormChanges}
              onClick={() => setpreview(false)}
              id="files"
              style={{
                backgroundColor: '#000',
                height: 35,
                width: 100,
                position: 'absolute',
                opacity: 0,
                cursor: 'pointer',
              }}
            />
            <label htmlFor="files" style={{ marginRight: '120px' , marginTop:"5px"}}>
              <Button
                variant="contained"
                component="span"
                htmlFor="files"
                style={{ fontWeight: 'bold' }}
              >
                Upload
                <FileUploadIcon />
              </Button>{' '}
            </label>
          </form>
        </div>
        <div className="rightnew">
          <form>
            <div className="formInputs">
              <label> Title</label>
              <input
                type="text"
                name="title"
                placeholder="Enter title"
                onChange={handleEditInput}
                value={editFormData.title}
              />
            </div>
            <div className="formInputs">
              <label>Sub Title</label>
              <input
                type="text"
                name="description"
                placeholder="Enter sub title"
                onChange={handleEditInput}
                value={editFormData.description}
              />
            </div>
           <div style={{ marginTop: '54px' }}>
            <button
              type="submit"
              onClick={saveEditInformation}
              style={{ marginRight:"20px"}}
            >
              Save
            </button>
            <button style={{  }}>
              Cancel
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditableRow;
