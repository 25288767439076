import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";

export const GetAllPartnerWithUs = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.ServerConstants.API_ROUTES.Partner_With_Us.GET_ALL_Partner}`,
    body,
    {
      headers,
    }
    // "http://52.250.20.141/silverleaf-backend/api/partnerWithUs/getAllPartners"
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetDeletePartnerWithUs = async (Id) => {
  return await AxiosInstance.delete(
    // `http://52.250.20.141/silverleaf-backend/api/partnerWithUs/deletePartner/${Id}`
    `${AppConstants.ServerConstants.API_ROUTES.Partner_With_Us.DELETE_Partner}/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};


export const EditPartnerWithUs = async (Id) => {
  return await AxiosInstance.get(
    // `http://52.250.20.141/silverleaf-backend/api/partnerWithUs/deletePartner/${Id}`
    `${AppConstants.ServerConstants.API_ROUTES.Partner_With_Us.EDIT_Partner}/${Id}`
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};
