import React, { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function ReadOnlyRow({ row, handleEditClick, handleDeleteClick }) {
  useEffect(() => {}, []);
  return (
    <div>
      {' '}
      <div className="bottomnew">
        <div className="leftnew">
          <img src={row.image} alt="" />
        </div>
        <div className="rightnew">
          <form>
            {/* <div className="formInput">
           <label htmlFor="file"> Image: <DriveFolderUploadIcon className='icon'/></label>
           <input type="file" name='image' onChange={(e)=> setFile(e.target.files[0])} id="file"  />
         </div> */}
            <div className="formInput">
              {/* <label style={{fontWeight: "bold",fontSize:"17px",color:'black'}}> Title</label> */}
              <div
                style={{ fontWeight: 'bold', fontSize: '17px', color: 'black' }}
              >
                Title
              </div>
              {/* <input type="text" name='title' placeholder="Enter title" value={row.title} onChange={handleInput}/> */}
              <div>{row.title}</div>
            </div>
            <div className="formInput">
              <label
                style={{ fontWeight: 'bold', fontSize: '17px', color: 'black' }}
              >
                Sub Title
              </label>
              {/* <input type="text" name="description"placeholder="Enter sub title" value={row.description} onChange={handleInput}/> */}
              <div>{row.description}</div>
            </div>
            <div
              className="formInput"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EditIcon
                onClick={(event) => handleEditClick(event, row)}
                style={{ marginRight: '100px', cursor: 'pointer' }}
              />
              <DeleteIcon
                onClick={() => {
                  handleDeleteClick(row._id);
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>

            {/* <button type='button' onClick={saveInfo}>Save</button> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ReadOnlyRow;
