import React from 'react';
import Modal from 'react-modal';
import Button from '@mui/material/Button';
import './style.css';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const ConfirmationModal = (props) => {
  return (
    <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
      <h1>{props.title}</h1>
      <p style={{color:"gray"}}>{props.message}</p>
      <div style={{ paddingTop: 30, marginLeft: 102 }}>
        {props.onClose && (
          <Button type="button" onClick={props.onClose} variant="contained" style={{width:"100px" , marginRight:"27px",backgroundColor:"green"}}>
            Cancel
          </Button>
        )}
        {props.onSuccess && (
          <Button type="button" onClick={props.onSuccess} variant="contained" style={{width:"100px",backgroundColor:"rgb(220,20,60)"}}>
            Ok
          </Button>
        )}
      </div>
    </Modal>
    
  );
};

export default ConfirmationModal;
