import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { CreateBlog } from '../../handlers/blogs.handlers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import '../../assets/style.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const AddSocialMediaLinkBlog = (props) => {
  const navigate = useNavigate();
  const [addEmbed, setAddEmbed] = useState(false);
  const [file, setFile] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [userInfo, setUserInfo] = useState({
    file: '',
    embedPostLink: '',
    title: '',
    description: '',
    category: '',
    image: '',
    createdBy: '',
  });
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleFileInput = (e) => {
    let file = e.target.files[0];
    e.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        setFile(file);
        setUserInfo({ ...userInfo, file: file });
      };
    };
  };
  const onCategorySelected = (e) => {
    setUserInfo({ ...userInfo, category: e.target.value });
  };
  const saveInformation = () => {
    setIsLoadingMore(true);
    // if (userInfo.describtion !== '') {
    const data = {
      embedPostLink: userInfo.embedPostLink,
      title: userInfo.title,
      description: userInfo.description,
      video: userInfo.video,
      image: file,
      category: userInfo.category,
      createdBy: '',
    };
    const formData = new FormData();

    Object.keys(data).forEach(function (key) {
      formData.append(key, data[key]);
    });
    CreateBlog(formData).then((response) => {
      console.log('respnse', response);
      if (response.data.status === 200) {
        navigate('/allblogs');
        // alert(`${response.data.message}`);
        setIsLoadingMore(false);
        toast.info(`${response.data.message}`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        clearState();
      }
    });
    // }
    // } else if (userInfo.embedPostLink === '') {
    //   setIsLoadingMore(false);
    //   toast.error('Please add Embed Post Link', {
    //     position: 'top-center',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    //  else {
    //   setIsLoadingMore(false);
    //   toast.error('Error', {
    //     position: 'top-center',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  const clearState = () => {
    setUserInfo({
      embedPostLink: '',
    });
  };

  const cancleInformation = () => {
    clearState();
  };
  return (
    <div className="cases">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <>
          <div className="relative md:ml-64">
            <div className="flex flex-wrap">
              <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <h1 className="addArticle_heading">
                All Fields are mandatory:
              </h1> */}

                    <div style={{ marginTop: '20px' }}>
                      {addEmbed ? (
                        <Button
                          type="button"
                          onClick={() => {
                            setAddEmbed(false);
                          }}
                          variant="contained"
                          className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 `}
                          style={{
                            marginRight: '30px',
                            width: 'auto',
                            fontWeight: 800,
                          }}
                        >
                          Add Custom Blog
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          onClick={() => {
                            setAddEmbed(true);
                          }}
                          variant="contained"
                          className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                          style={{ width: 'auto', fontWeight: 800 }}
                        >
                          Add Embed blog
                        </Button>
                      )}
                      {addEmbed == false && (
                        <>
                          <div className="blog-form">
                            <form action="">
                              <div
                                style={{
                                  marginTop: '20px',
                                  marginBottom: '20px',
                                }}
                              >
                                <label className="addArticle_label">
                                  Video Link
                                </label>
                                <br></br>
                                <input
                                  type="text"
                                  placeholder="Video Link"
                                  name="video"
                                  value={userInfo.video}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="addBlog-img">
                                <img
                                  src={file && URL.createObjectURL(file)}
                                  alt="File"
                                  width="250px"
                                  height="200px"
                                />
                              </div>
                              <br />
                              <input
                                type="file"
                                name="file"
                                onChange={handleFileInput}
                              />
                              <label className="addArticle_label">Title*</label>
                              <input
                                type="text"
                                placeholder="Title*"
                                name="title"
                                value={userInfo.title}
                                onChange={handleInput}
                              />
                              <select
                                style={{
                                  //   marginLeft: 20,
                                  border: '2px solid #acb9ce',
                                  borderRadius: 10,
                                  marginBottom: '1.25rem',
                                  marginTop: '0.75rem',
                                  width: '160px',
                                  height: '47px',
                                }}
                                className="mb-5 mt-3"
                                value={userInfo.category}
                                onChange={onCategorySelected}
                              >
                                <option value="">Category</option>
                                <option value="pharmaceutical">
                                  Pharmaceutical
                                </option>
                                <option value="automobile">Automobile</option>
                                <option value="outsourcing">Outsourcing</option>
                                <option value="bFSI">BFSI</option>
                                <option value="executive@">Executive</option>
                              </select>
                            </form>
                          </div>
                          <label className="addArticle_label">
                            Description*
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={userInfo.description}
                            //   onChange={event => setDescription(event.target.value)}
                            onChange={(event, editor) => {
                              // setDescription(editor.getData())
                              setUserInfo({
                                ...userInfo,
                                description: editor.getData(),
                              });
                            }}
                          />
                          <br />
                        </>
                      )}
                      {addEmbed && (
                        <>
                          <label className="addArticle_label">
                            Add Embed Link
                          </label>
                          <br /> <br />
                          <textarea
                            rows="3"
                            cols="100"
                            type="text"
                            placeholder="Embed Post Link*"
                            name="embedPostLink"
                            value={userInfo.embedPostLink}
                            onChange={handleInput}
                            style={{ borderRadius: '4px' }}
                          ></textarea>
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        padding: '30px 0',

                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        type="button"
                        onClick={saveInformation}
                        variant="contained"
                        className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        style={{ width: 'auto', fontWeight: '800' }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={cancleInformation}
                        className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="button"
                        style={{
                          backgroundColor: 'red',
                          marginLeft: '10px',
                          fontWeight: '800',
                          color: 'white',
                          width: 'auto',
                        }}
                      >
                        {/* {'Draft Article'} */}
                        Cancel
                      </Button>
                    </div>
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: userInfo.embedPostLink,
                        }}
                      />
                    }
                  </div>
                </div>
                <div className="row">
                  {/* <label>Key Words*</label><br/> */}

                  <div>
                    <></>
                  </div>
                </div>
              </div>
            </div>
            <ScaleLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={20}
            />
          </div>
        </>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddSocialMediaLinkBlog;
