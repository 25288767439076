import React, { useState, useEffect } from 'react';
import './list.css';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableCell } from '@mui/material';
import { TableBody } from '@mui/material';
import { Table } from '@mui/material';
import { TableContainer } from '@mui/material';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import EditIcon from '@mui/icons-material/Edit';
import Single from '../single/AddCaseStudies';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  GetAllCaseStudies,
  GetDeleteCaseStudies,
} from '../../handlers/caseStudies.handler';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReactHtmlParser from 'html-react-parser';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
function List(props) {
  const { numInfo, setnumInfo } = props;
  console.log('props', numInfo);
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [caseId, setCaseId] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const openUpdate = '';

  useEffect(() => {
    getCaseStudy();
  }, [props.num, page]);

  const handlePageClick = (event, newPage) => {
    setPage(newPage);
    console.log(page);
  };
  const getCaseStudy = () => {
    setIsLoadingMore(true);
    const data = {
      page: page,
      limit: 6,
    };
    console.log('page', data);
    GetAllCaseStudies(data).then((response) => {
      if (response.message === 'success') {
        setData(response.data.data.caseStudyList);
        setCount(response.data.data.totalPages);
        console.log('response.data.data', response.data.data);
        setIsLoadingMore(false);
      } else alert('Something went wrong');
    });
  };
  const handleDeleteClick = (id) => {
    setCaseId(id);
    showModalForPublish();
  };
  const showModalForPublish = () => {
    let title = 'Delete Case Study ?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const onSuccess = () => {
    setIsLoadingMore(true);
    GetDeleteCaseStudies(caseId).then((response) => {
      if (response.status === true) {
        console.log('donecase');
        setIsLoadingMore(false);
        toast.success('Case Studies deleted succecfully!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setConfirmationModal({
          message: '',
          show: false,
          title: '',
        });
        getCaseStudy();
      }
    });
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {confirmationModal.show && (
          <ConfirmationModal
            message={confirmationModal.message}
            title={confirmationModal.title}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Image
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Title
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Description
                </th>
                {/* <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Challenge
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Result
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  solution
                </th> */}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Category
                </th>{' '}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Client Name
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Tag
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Video Link
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row) => (
                <tr>
                  <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {/* <AspectRatio
                      ratio={16 / 9}
                      style={{
                        background : `url('${news.imgUrl}')`,
                        width:'450px',
                        height:'253px',
                        backgroundSize : 'cover',
                        backgroundRepeat :'no-repeat'
                      }}
                    > */}
                    {/* <img
                        src={news.imgUrl}
                        className="h-12 w-12 bg-white"
                        alt="..."
                      ></img> */}
                    {/* </AspectRatio> */}

                    <div
                      style={{
                        background: `url('${row.image}?${row._id}') 0% 0% / cover no-repeat`,
                        width: '100px',
                        height: '53px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    ></div>
                  </th>
                  <td
                    className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    style={{ width: '138px' }}
                  >
                    {row.title}
                  </td>
                  <td
                    className="border border-green-800 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    style={{ width: '400px' }}
                  >
                    {row && row.description && row.description.length < 30
                      ? ReactHtmlParser(row.description)
                      : ''}
                    {row && row.description && row.description.length > 65
                      ? ReactHtmlParser(row.description.slice(0, 65) + '...')
                      : ''}
                  </td>
                  {/* <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '400px' }}
                    >
                      {row && row.challenge && row.challenge.length < 30
                        ? ReactHtmlParser(row.challenge)
                        : ''}
                      {row && row.challenge && row.challenge.length > 65
                        ? ReactHtmlParser(row.challenge.slice(0, 65) + '...')
                        : ''}
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '400px' }}
                    >
                      {row && row.result && row.result.length < 30
                        ? ReactHtmlParser(row.result)
                        : ''}
                      {row && row.result && row.result.length > 65
                        ? ReactHtmlParser(row.result.slice(0, 65) + '...')
                        : ''}
                      
                    </td>
                    <td
                      className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      style={{ width: '400px' }}
                    >
                      {row && row.solution && row.solution.length < 30
                        ? ReactHtmlParser(row.solution)
                        : ''}
                      {row && row.solution && row.solution.length > 65
                        ? ReactHtmlParser(row.solution.slice(0, 65) + '...')
                        : ''}
                      
                    </td> */}
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {row.category}
                  </td>
                  <td
                    className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    style={{ width: '104px' }}
                  >
                    {row && row.clientName == 'undefined'
                      ? 'N/A'
                      : row.clientName
                      ? row.clientName
                      : 'N/A'}
                  </td>
                  <td
                    className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    style={{ width: '104px' }}
                  >
                    {row && row.tags == 'undefined'
                      ? 'N/A'
                      : row.tags
                      ? row.tags
                      : 'N/A'}
                  </td>
                  <td
                    className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    style={{ width: '104px' }}
                  >
                    {row && row.video == 'undefined'
                      ? 'N/A'
                      : row.video
                      ? row.video.slice(0, 10) + '...'
                      : 'N/A'}
                  </td>
                  <td
                    className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    style={{ width: '133px' }}
                  >
                    <Link to={`/updateCaseStudies/${row._id}`}>
                      <EditIcon
                        // onClick={(event) => handleEditClick(event, row)}
                        style={{ cursor: 'pointer', marginRight: '22px' }}
                      />
                    </Link>
                    <DeleteIcon
                      onClick={() => {
                        handleDeleteClick(row._id);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Stack style={{ alignItems: 'center', margin: '19px' }}>
          <Pagination
            page={page}
            onChange={handlePageClick}
            count={count}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </Stack>
      </div>
      <div>
        <ScaleLoader
          color="#23A5E0"
          loading={isLoadingMore}
          css={override}
          size={20}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default List;
